import React, {
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Switch from 'react-switch';
import { HiCheck, HiOutlinePlusSm } from 'react-icons/hi';
import styles from './switch.module.scss';

const StyledSwitch = ({ handleChange }, ref) => {
  const [checked, setChecked] = useState(true);

  useImperativeHandle(ref, () => ({
    setValue: (bool) => setChecked(bool),
  }));

  return (
    <Switch
      className={styles.switch}
      onChange={() => {
        if (handleChange) handleChange(!checked);
        setChecked((state) => !state);
      }}
      checked={checked}
      offColor={'#DEF1F9'}
      onColor={'#DEF1F9'}
      offHandleColor="#00A8E8"
      onHandleColor="#00A8E8"
      activeBoxShadow={'0 0 2px 3px transparent'}
      checkedIcon={false}
      uncheckedIcon={false}
      checkedHandleIcon={
        <div className={styles.icon}>
          <HiCheck />
        </div>
      }
      uncheckedHandleIcon={
        <div className={styles.icon} style={{ transform: 'rotate(-45deg)' }}>
          <HiOutlinePlusSm />
        </div>
      }
    />
  );
};

export default forwardRef(StyledSwitch);
