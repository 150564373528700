import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, NavLink } from 'react-router-dom';
import { FiThumbsUp } from 'react-icons/fi';
import { postFeedback } from '../../actions/quiz';
import styles from './feedback.module.scss';
import Textarea from '../../components/Textarea';
import blueStar from '../../assets/images/icons/bonus-star.svg';
import logout from '../../assets/images/icons/logout-blue.svg';
import { isTooLate } from '../../helpers';

const Feedback = () => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const history = useHistory();
  const [note, setNote] = useState(0);
  const [done, setDone] = useState(false);
  const commentRef = useRef();
  const timerRef = useRef();

  useEffect(() => (() => {
    if (timerRef.current) clearTimeout(timerRef.current);
  }), []);

  async function submit() {
    const succeed = await postFeedback({
      client_note: note,
      client_feedback: commentRef.current.getValue(),
    });
    setDone(succeed);
    setTimeout(() => history.push('/app/home'), 2000);
  }

  return (
    <div className={styles.feedback}>
      {isTooLate(quizReducer?.session) && <Redirect to='/app/result/teams' />}
      {done ? (
        <div className={styles.greetings}>
          <FiThumbsUp color={'#00A8E8'} size={'70px'} />
          <h3>Merci pour votre retour{'\u00A0'}!</h3>
        </div>
      ) : (
        <div className={styles.container}>
          <div>
            <div className={styles['select-note']}>
              <h2>Feedback</h2>
              <p>Notez ce quiz !</p>
              <div>
                {new Array(5).fill(null).map((d, i) => (
                  <div
                    key={`start-${i}`} onClick={() => setNote(i + 1)}
                    className={note >= i + 1 ? styles.star : `${styles.star} ${styles.disabled}`}
                  >
                    <img src={blueStar} alt={`note ${i + 1}`} />
                  </div>
                ))}
              </div>
            </div>
            <Textarea placeholder='Votre commentaire...' ref={commentRef} name={'comment'} />
            <div className={styles['container-button']}>
              <button className='secondary' onClick={() => submit()}>Soumettre</button>
            </div>
          </div>
          <div className={`only-on-tablet ${styles['link-home']}`}>
            <NavLink to='/app/home'>
              <img src={logout} alt="quittez l'animation" />
              <p>Quitter l’animation</p>
            </NavLink>
          </div>
        </div>
      )
      }
    </div>
  );
};

export default Feedback;
