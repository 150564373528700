import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isTooLate } from '../../helpers';
import styles from './login.module.scss';
import getQuizSeriesByToken from '../../actions/quiz';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const quizReducer = useSelector((state) => state.quizReducer);

  // HANDLE ALREADY LOG
  useEffect(() => {
    const { token } = params;
    if (token) {
      getQuizSeriesByToken(dispatch, token);
    }
  }, [params]);

  useEffect(() => {
    if (quizReducer.series) {
      if (isTooLate(quizReducer.session)) {
        history.push('/app/result/teams');
      } else {
        history.push('/app/home');
      }
    }
  }, [quizReducer.series]);

  return (
    <div className={styles.login}>
    </div>
  );
};

export default Login;
