import React from 'react';
import styles from './loader-spinner.module.scss';

const LoaderSpinner = ({ white = false, blue = false, size = 'md' }) => {
  function getClassName() {
    let className = `${styles.loader} ${styles[size]}`;
    if (white) className += ` ${styles.white}`;
    if (blue) className += ` ${styles.blue}`;
    return className;
  }
  return (
    <div className={getClassName()}></div>
  );
};

export default LoaderSpinner;
