import axios from 'axios';
import {
  getData,
  putData,
  postData,
  postDataFormData,
  errorHandler,
} from './index.js';
import { API_URL, OFFLINE } from '../constant';
import {
  GET_QUIZ_SERIES,
  RELOAD_QUIZ_SERIES,
  GET_QUIZ_TEAMS,
  PUT_SCORES,
  PUT_SESSION,
  PUT_FEEDBACK_QUESTION,
  PUT_CLIENT_HELP,
  ADD_TEAM,
  SET_LOADING_TYPE,
  QUIZ_LOADING,
  CREATE_TEAM_LOADING,
  ERROR_QUIZ,
} from './types.js';

export default async function getQuizSeriesByToken(dispatch, token) {
  let url = '/quiz/series';
  if (OFFLINE) url += '/offline';

  const requestUrl = API_URL + url;
  let series = [];
  let session = null;
  let teams = [];
  let advices = [];

  dispatch({
    type: QUIZ_LOADING,
  });

  const config = {};
  config.headers = {
    Authorization: `${token}`,
  };

  axios.get(requestUrl, config)
    .then(async (response) => {
      series = response.data.series;
      localStorage.setItem('token', token);

      let url2 = '/quiz/session';
      if (OFFLINE) url2 += '/offline';

      await getData(ERROR_QUIZ, url2, dispatch, true).then((response2) => {
        session = response2.data.session;
      });

      let url3 = '/quiz/teams';
      if (OFFLINE) url3 += '/offline';

      await getData(ERROR_QUIZ, url3, dispatch, true).then((response3) => {
        teams = response3.data.teams;
      });

      const url4 = '/quiz/conseil';

      await getData(ERROR_QUIZ, url4, dispatch, true).then((response4) => {
        advices = response4.data.conseils;
      });

      dispatch({
        type: GET_QUIZ_SERIES,
        payload: {
          series,
          session,
          teams,
          advices,
        },
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error, ERROR_QUIZ);
    });
}

export async function getQuizSeries(dispatch) {
  let url = '/quiz/series';
  let series = [];
  let session = null;
  let teams = [];
  let advices = [];

  if (OFFLINE) url += '/offline';

  dispatch({
    type: QUIZ_LOADING,
  });

  await getData(ERROR_QUIZ, url, dispatch, true).then((response) => {
    series = response.data.series;
  });

  let url2 = '/quiz/session';
  if (OFFLINE) url2 += '/offline';

  await getData(ERROR_QUIZ, url2, dispatch, true).then((response) => {
    session = response.data.session;
  });

  let url3 = '/quiz/teams';
  if (OFFLINE) url3 += '/offline';

  await getData(ERROR_QUIZ, url3, dispatch, true).then((response) => {
    teams = response.data.teams;
  });

  const url4 = '/quiz/conseil';

  await getData(ERROR_QUIZ, url4, dispatch, true).then((response) => {
    advices = response.data.conseils;
  });

  dispatch({
    type: GET_QUIZ_SERIES,
    payload: {
      series,
      session,
      teams,
      advices,
    },
  });
}

export function sendWsEvent(data) {
  const url = `${API_URL}/quiz/ws`;
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  axios.post(url, data, config)
    .then(() => {
      // console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getFeedbackOptions() {
  const url = `${API_URL}/quiz/feedback/question`;
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  const res = await axios.get(url, config);
  let results = [];
  if (res.statusText === 'OK') results = res.data;
  return results;
}

export async function putFeedbackQuestion(dispatch, data) {
  const url = '/quiz/feedback/question';

  const promise = await putData(ERROR_QUIZ, true, url, dispatch, data).then(() => {
    // console.log(response);
  });

  dispatch({
    type: PUT_FEEDBACK_QUESTION,
    payload: data,
  });

  return promise;
}

export async function putClientHelp(dispatch, bool) {
  const url = '/quiz/client/help';
  const data = { flag_espace_client_aide: bool ? 1 : 0 };
  const promise = await putData(ERROR_QUIZ, true, url, dispatch, data).then(() => {
    // console.log(response);
  });

  dispatch({
    type: PUT_CLIENT_HELP,
    payload: data,
  });

  return promise;
}

export async function putReloadQuestion(dispatch, data, dataReload) {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  try {
    // PUT FEEDBACK
    const url = `${API_URL}/quiz/feedback/question`;
    await axios.put(url, data, config);
    // POST RELOAD QUESTION
    const urlReload = `${API_URL}/quiz/reload/question`;
    await axios.post(urlReload, dataReload, config);
    // GET SERIES WITH QUESTION RELOADED
    const urlReload3 = `${API_URL}/quiz/series`;
    const res = await axios.get(urlReload3, config);
    dispatch({
      type: RELOAD_QUIZ_SERIES,
      payload: res.data.series,
    });
    return 'series reloaded';
  } catch (error) {
    throw new Error(error.response?.config.url.replace(API_URL, ''));
  }
}

export async function getQuizTeams(dispatch) {
  let teams = [];
  let url = '/quiz/teams';
  if (OFFLINE) url += '/offline';

  await getData(ERROR_QUIZ, url, dispatch, true).then((response) => {
    teams = response.data.session;
  });

  dispatch({
    type: GET_QUIZ_TEAMS,
    payload: { teams },
  });
}

export async function postFeedback(data) {
  const url = '/quiz/feedback';
  let succeed;
  await putData(null, true, url, null, data).then((response) => {
    succeed = true;
    console.log(response);
  });
  return succeed;
}

export async function postSessionImage(dispatch, data) {
  const url = '/quiz/session/image';
  const promise = await postDataFormData(ERROR_QUIZ, true, url, dispatch, data).then(() => {
    // console.log(response);
  });
  // dispatch({
  //   type: PUT_SESSION_IMAGE
  // });
  return promise;
}

export async function postTeamImage(dispatch, data) {
  const url = '/quiz/team/image';
  const promise = await postDataFormData(ERROR_QUIZ, true, url, dispatch, data).then((response) => {
    console.log(response);
  });
  // dispatch({
  //   type: PUT_SESSION_IMAGE
  // });
  return promise;
}

export async function putSession(dispatch, data) {
  const url = '/quiz/session';
  dispatch({
    type: SET_LOADING_TYPE,
    payload: 'set_session',
  });
  const promise = await putData(ERROR_QUIZ, true, url, dispatch, data).then(() => {
    // console.log(response);
  });
  dispatch({
    type: PUT_SESSION,
    payload: data,
  });
  return promise;
}

export async function putTeamsBonus(dispatch, teams) {
  const url = '/quiz/score';
  dispatch({
    type: SET_LOADING_TYPE,
    payload: 'set_bonus',
  });
  await putData(ERROR_QUIZ, true, url, dispatch, teams).then(() => {
    // console.log(response);
  });
  dispatch({
    type: PUT_SCORES,
    payload: teams,
  });
}

export async function createTeam(dispatch, data) {
  const url = '/quiz/team';
  let team;
  let teamID = null;

  dispatch({
    type: CREATE_TEAM_LOADING,
  });

  await postData(ERROR_QUIZ, url, dispatch, data, true).then((response) => {
    // console.log(response);
    teamID = response.data.teamId;
  });

  if (teamID) {
    await getData(ERROR_QUIZ, `${url}/${teamID}`, dispatch, true).then((response) => {
      team = response.data.team;
    });
  }

  if (team) {
    dispatch({
      type: ADD_TEAM,
      payload: team,
    });
  }
}

export async function editTeam(dispatch, data, id) {
  const url = `/quiz/team/${id}`;
  let team;
  let teamID = null;

  dispatch({
    type: CREATE_TEAM_LOADING,
  });
  await putData(ERROR_QUIZ, true, url, dispatch, data).then((response) => {
    teamID = response.teamId;
  });

  if (teamID) {
    await getData(ERROR_QUIZ, `${url}`, dispatch, true).then((response) => {
      team = response.data.team;
    });
  }

  if (team) {
    dispatch({
      type: ADD_TEAM,
      payload: team,
    });
  }
}

export async function putTeamsScore(dispatch, teams) {
  const url = '/quiz/score';
  dispatch({
    type: SET_LOADING_TYPE,
    payload: 'set_score',
  });
  await putData(ERROR_QUIZ, true, url, dispatch, teams).then(() => {
    // console.log(response);
  });
  dispatch({
    type: PUT_SCORES,
    payload: teams,
  });
}
