import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { flatten } from 'lodash';
import { useSelector } from 'react-redux';
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5';
import { AiFillHome } from 'react-icons/ai';
import {
  Redirect,
  NavLink,
  Prompt,
  useParams,
  useHistory,
} from 'react-router-dom';
import usePrevious from '../../hooks/usePrevious';
import { getMediaType, isTooLate } from '../../helpers';
import { sendWsEvent } from '../../actions/quiz';
import styles from './quiz.module.scss';
import SliderQuiz from '../../components/SliderQuiz';
import CardQuizz from '../../components/CardQuiz';
import QuickView from '../../components/QuickView';
import Conseil from '../../components/Conseil';

import ResultTeams from '../../components/ResultTeams';
import PhotoTeams from '../../components/PhotoTeams';

import CorrectionSerie from '../../components/CorrectionSerie';
import SliderQuizNav from '../../components/SliderQuizNav';
import help from '../../assets/images/icons/help.svg';
import ellipse from '../../assets/images/ellipse.svg';
import imgSwipe from '../../assets/images/icons/swipe.svg';
// eslint-disable-next-line import/named
import { quizContext } from './quizContext.js';

const Quiz = () => {
  const history = useHistory();
  const params = useParams();
  const quizReducer = useSelector((state) => state.quizReducer);
  const [indexSerie, setIndexSerie] = useState();
  const [pauseFocused, setPauseFocused] = useState(null);
  // const pauseWasFocused = usePrevious(pauseFocused);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [indexCurrentQuestion, setCurrentQuestion] = useState(null);
  const indexPreviousQuestion = usePrevious(indexCurrentQuestion);
  const [hideLogout, setHideLogout] = useState(false);
  const SliderQuizRef = useRef();
  const SliderQuizNavRef = useRef();
  const QuickviewRef = useRef();

  function handleChangeSlide(index, isLast) {
    setHideLogout(isLast);
    SliderQuizRef.current.goToSlide(index);
    setCurrentQuestion(index);
  }

  function updateIndex(index) {
    setCurrentQuestion(index);
    SliderQuizNavRef.current.goToSlide(index);
  }

  function launchMediaOnRemote(question, source) {
    sendWsEvent({
      action: 'launch_media',
      path: 'serie',
      serie: quizReducer.series?.[indexSerie].id,
      question: question.id,
      src: getMediaType(source),
    });
  }

  function stopMediaOnRemote() {
    sendWsEvent({
      action: 'stop_media',
    });
  }

  function getIfTouchDevice() {
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    return supportsTouch;
  }

  function getQuestions(i) {
    return ([
      ...quizReducer.series[i].questions,
      {
        indice: quizReducer.session.bonus[`indice_${i + 1}`],
        reponse: quizReducer.session.bonus.reponse,
        commentaire: quizReducer.session.bonus.commentaire,
      },
    ]);
  }

  // SET BOUND SLIDE WHEN INDEX SERIE CHANGE
  useEffect(() => {
    if (typeof indexSerie === 'number' && SliderQuizRef.current) {
      if (quizReducer.series.length === indexSerie) setHideLogout(true);
      SliderQuizRef.current.setBoundSlide();
    }
  }, [indexSerie]);

  // HANDLE SCROLL TOP QUICKVIEW
  useEffect(() => {
    if (QuickviewRef.current && !isQuickViewOpen) {
      QuickviewRef.current.scrollTop();
    }
  }, [
    QuickviewRef.current,
    isQuickViewOpen,
  ]);

  useEffect(() => {
    if (!SliderQuizRef.current) return;
    setTimeout(() => {
      SliderQuizRef.current.setBoundSlide();
    }, 800);
  }, [isQuickViewOpen]);

  // function remoteNavigateToHome() {
  //   sendWsEvent({
  //     path: 'start',
  //   });
  // }

  function getItemsNavigation() {
    const seriesItems = [];
    quizReducer.series.forEach((serie, j) => {
      const items = [];
      if (j === 0) items.push({ value: 'serie 1', serie: j });
      quizReducer.series[j]?.questions.forEach((d, i) => {
        items.push({ value: i + 1, serie: j, idQuestion: d.id });
      });
      if (quizReducer.session.bonus[`indice_${j}`]) items.push({ value: 'bonus', serie: j, bonus: true });
      if (j === quizReducer.series.length - 1) items.push({ value: 'fin', serie: j });
      else items.push({ value: 'pause', serie: j });
      seriesItems.push(items);
    });
    return flatten([
      ...seriesItems,
      { value: 'classement', serie: quizReducer.series.length },
      { value: 'photos', serie: quizReducer.series.length },
    ]);
  }

  // INIT SLIDER CURRENT SERIE
  // AND CURRENT QUESTION
  useEffect(() => {
    if (!indexCurrentQuestion && indexCurrentQuestion !== 0) {
      const serie = params.serie || 0;
      setIndexSerie(parseInt(serie, 10));
      const currentQuestion = getItemsNavigation().findIndex((d) => (
        d.serie === parseInt(serie, 10)
      ));
      setCurrentQuestion(currentQuestion);
    }
  }, [indexCurrentQuestion]);

  // UPDATE CURRENT SERIE
  useEffect(() => {
    if (typeof indexPreviousQuestion === 'number') {
      const items = getItemsNavigation();
      const currentSerie = items[indexCurrentQuestion].serie;
      const nextSerie = parseInt(currentSerie, 10);
      if (indexSerie !== nextSerie) history.push(`/app/quiz/${nextSerie}`);
      setIndexSerie(nextSerie);
    }
  }, [indexCurrentQuestion]);

  function getSeriesQuestions() {
    const seriesQuestions = [];
    quizReducer.series.forEach((serie, j) => {
      seriesQuestions.push([
        <React.Fragment key={'serie-intructions'}>
          {j === 0 && (
            <div className={`${styles.help}`}>
              {getIfTouchDevice()
                ? <div>
                  <div className={quizReducer.showAdvices ? styles['with-advices'] : styles['without-advices']}>
                    <h4>
                      Swipe à droite pour faire défiler les questions
                    </h4>
                    <img className={styles.iconSwipe} src={imgSwipe} alt="swipe à droite pour faire défiler les questions" />
                  </div>
                  <Conseil containerClassName={styles['help-advice']} className={styles.advices} zoningCode='animation_introduction' />
                </div>
                : <div>
                  <div className={quizReducer.showAdvices ? styles['with-advices'] : styles['without-advices']}>
                    <h4>Utilise les flèches pour faire défiler les questions</h4>
                  </div>
                  <Conseil containerClassName={styles['help-advice']} className={styles.advices} zoningCode='animation_introduction' />
                </div>
              }
            </div>
          )}
        </React.Fragment>,
        ...getQuestions(j).map((d, i) => <CardQuizz
          key={`question-${d.id}`}
          id={d.id}
          index={i}
          indexSerie={j}
          rubrique={d.rubrique}
          difficulty={d.difficulte}
          points={d?.points?.label}
          question={d.question}
          fnLaunchMediaOnRemote={() => launchMediaOnRemote(d, d.lien_support)}
          fnStopMediaOnRemote={() => stopMediaOnRemote()}
          media={(d.lien_support && d.id_type_support !== 0)
            ? {
              type_support: d.id_type_support,
              type: getMediaType(d.lien_support),
              source: d.lien_support,
            } : {}
          }
          indice={d.indice}
          answer={{
            text: d.reponse,
            detail: d.commentaire,
          }}
        />),
        <CorrectionSerie
          key={'serie-end'}
          indexSerie={j}
          lastSerie={(quizReducer.series.length - 1) === j}
          isFocused={pauseFocused}
          fnNextQuestion={() => setCurrentQuestion((state) => {
            console.log(state);
            return state + 1;
          })}
        />,
      ]);
    });
    return flatten([
      ...seriesQuestions,
      <ResultTeams key={'result-team'} />,
      <PhotoTeams key={'photos-team'} />,
    ]);
  }

  function getStepsPause() {
    const items = getItemsNavigation();
    const pauses = items.map((g, i) => (
      (g.value === 'pause' || g.value === 'fin') ? i : null
    ));
    pauses.push(items.length - 2);
    pauses.push(items.length - 1);
    return pauses.filter((d) => d);
  }

  // HANDLE IS PAUSE FOCUSED
  // STOP MEDIA WHEN QUESTION CHANGE
  useEffect(() => {
    if (typeof indexCurrentQuestion !== 'number') return;
    const isPauseFocused = getStepsPause().includes(indexCurrentQuestion);
    const step = getItemsNavigation()[indexCurrentQuestion];
    const serieIndex = step.serie;

    let wsObject = null;
    if (isPauseFocused) {
      const nextSerie = quizReducer.series?.[serieIndex + 1];
      if (nextSerie) {
        wsObject = {
          path: 'stop',
          serie: quizReducer.series?.[serieIndex].id,
        };
      } else {
        wsObject = {
          path: 'end',
        };
      }
    } else if (typeof serieIndex === 'number' && quizReducer.series?.[serieIndex]) {
      // WHEN CHANGE QUESTION BUT PAUSE WASN'T FOCUSED
      // stopMediaOnRemote();
      wsObject = {
        action: 'change_question',
        question: step?.idQuestion,
        serie: quizReducer.series?.[serieIndex].id,
        bonus: !!step?.bonus,
      };
    }
    if (wsObject) sendWsEvent(wsObject);
    setPauseFocused(isPauseFocused);
  }, [indexCurrentQuestion]);

  return (
    <quizContext.Provider value={{ isQuickViewOpen, setIsQuickViewOpen }}>
      {isTooLate(quizReducer?.session) && <Redirect to='/app/result/teams' />}
      {(typeof indexSerie === 'number') && (
        <>
          <div className={!isQuickViewOpen ? `${styles.quiz} ${styles.fullwidth}` : styles.quiz}>
            <div className={isQuickViewOpen ? styles.sidebar : `${styles.sidebar} ${styles.closed}`}>
              <p className={styles.response}>Afficher les réponses</p>
              <div className={styles.leave}>
                <NavLink to='/app/help'>
                  <img src={help} alt="quittez l'animation et consulter l'aide" />
                </NavLink>
              </div>
              <div className={styles['container-quickview']}>
                <QuickView ref={QuickviewRef}/>
              </div>
              <div className={styles.toggle} onClick={() => setIsQuickViewOpen((state) => !state)}>
                <img src={ellipse} alt="ouvrir l'aperçu du quizz" />
                {!isQuickViewOpen
                  ? <IoChevronForwardOutline size={'18px'} />
                  : <IoChevronBackOutline size={'18px'} />
                }
              </div>
            </div>
            <div className={`${styles['container-quiz']} ${styles[`serie-${quizReducer.series[indexSerie] ? indexSerie + 1 : 'end'}`]}`}>
              <SliderQuizNav
                ref={SliderQuizNavRef}
                indice={!!quizReducer.session.bonus[`indice_${indexSerie}`]}
                countQuestions={quizReducer.series[indexSerie]?.questions.length}
                items={getItemsNavigation()}
                stepsPause={getStepsPause()}
                countSeries={quizReducer.series.length}
                indexSerie={indexSerie}
                currentIndex={indexCurrentQuestion}
                isQuickViewOpenDesktop={isQuickViewOpen}
                detailSerie={{
                  index: `${indexSerie + 1}/${quizReducer.series.length}`,
                  univers: quizReducer.series[indexSerie]?.nom ? (
                    quizReducer.series[indexSerie]?.nom
                  ) : (
                    quizReducer.series[indexSerie]?.questions[0].univers
                  ),
                }}
                handleChangeSlide={handleChangeSlide}
              />
              {(quizReducer.series) && (
                <SliderQuiz
                  ref={SliderQuizRef}
                  indexSerie={indexSerie}
                  currentIndex={indexCurrentQuestion}
                  updateIndex={(index) => updateIndex(index)}
                  stepsPause={getStepsPause()}
                  univers={quizReducer.series[indexSerie]?.nom ? (
                    quizReducer.series[indexSerie]?.nom
                  ) : (
                    quizReducer.series[indexSerie]?.questions[0].univers
                  )}
                >
                  {getSeriesQuestions()}
                </SliderQuiz>)}
              <NavLink to='/app/home' className={hideLogout ? `${styles.logout} ${styles.hide}` : styles.logout}>
                <AiFillHome size={24} />
              </NavLink>
            </div>
          </div>
          <Prompt
            message={(location, action) => {
              if (action === 'POP') {
                console.log('Backing up...');
              }
              let message = 'Voulez-vous vraiment quitter le mode animation ?\nVotre progression dans l’animation sera perdue.';
              if (
                location.pathname === '/app/feedback'
                || location.pathname.startsWith('/app/quiz')
              ) {
                message = true;
              }
              if (location.pathname === '/app/home') {
                sendWsEvent({
                  path: 'start',
                });
              }
              return message;
            }}
          />
        </>
      )
      }
    </quizContext.Provider >

  );
};

export default Quiz;
