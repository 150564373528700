import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import rootReducer from './reducers';

import Login from './pages/login';
import ProtectedRoutes from './ProtectedRoutes';

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/:token">
            <Login />
          </Route>
          <Route exact path="/app/:page/:subpage?">
            <ProtectedRoutes/>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
