import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { putReloadQuestion } from '../../actions/quiz';
import ModalStandalone from '../../lib/ModalStandalone';
import closemodal from '../../assets/images/icons/close-modal.svg';
import styles from './modal-feedback.module.scss';
import LoaderSpinner from '../LoaderSpinner';

const ModalFeedback = ({
  modalRef,
  positionModal,
  opts,
  idSerie,
  question,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  function reloadQuestion(opt) {
    const obj = {
      id_question: question.id,
      flag_feedback_plus: typeof question.flag_feedback_plus === 'number' ? question.flag_feedback_plus : 0,
      flag_feedback_moins: typeof question.flag_feedback_moins === 'number' ? question.flag_feedback_moins : 0,
      id_reload_type: opt.id,
    };
    setIsLoading(true);

    const objReload = {
      id_question: question.id,
      id_serie: idSerie,
      numero_question: question.numero,
      id_questions_feedback_reload_type: opt.id,
    };
    putReloadQuestion(dispatch, obj, objReload).then(() => {
      setIsLoading(false);
    }).catch((res) => {
      console.log(res);
      setIsLoading(false);
    });
  }

  return (
    <ModalStandalone ref={modalRef}>
      <ul
        className={styles['modal-reload']}
        style={positionModal}
      >
        {!positionModal.below && (
          <li className={styles.close}>
            {isLoading
              ? <div className={styles.loader}><LoaderSpinner size={'sm'} white /></div>
              : <img src={closemodal} alt="Fermer" onClick={() => modalRef.current.toggle()} />
            }
          </li>
        )}
        {opts.sort((a, b) => (a.position > b.position && 1) || -1).map((opt) => (
          <li key={`opt-${opt.id}`} onClick={() => reloadQuestion(opt)}>{opt.nom}</li>
        ))}
        {positionModal.below && (
          <li className={styles.close}>
            {isLoading
              ? <div className={styles.loader}><LoaderSpinner size={'sm'} white /></div>
              : <img src={closemodal} alt="Fermer" onClick={() => modalRef.current.toggle()} />
            }
          </li>
        )}
      </ul>
    </ModalStandalone>
  );
};

export default ModalFeedback;
