import React, {
  useRef, useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import styles from './textarea.module.scss';

const Textarea = forwardRef((
  {
    name,
    control,
    rules = {},
    widthLabel = null,
    inline = false,
    disabled = false,
    label = null,
    placeholder = '',
    defaultValue,
    maxLength = null,
    className,
    // eslint-disable-next-line no-unused-vars
    ...rest
  }, ref,
) => {
  const [textareaValue, setTextareaValue] = useState('');
  const [style, setStyle] = useState({});
  const textareaRef = useRef();
  const textareaWatch = useWatch({
    control,
    name,
  });

  function autosize() {
    const elt = textareaRef.current.querySelector('textarea');
    if (!elt) return;
    elt.style.cssText = 'height:auto; padding:0';
    elt.style.cssText = `height:${elt.scrollHeight}px`;
    setStyle({
      padding: '10px',
      height: `${elt.scrollHeight}px`,
    });
  }

  function getClassName() {
    const classValue = styles[className] ? styles[className] : '';

    if (inline) {
      return disabled ? `${classValue} ${styles['container-textarea']} ${styles.disabled} ${styles.inline}` : `${classValue} ${styles['container-textarea']} ${styles.inline}`;
    }
    return disabled ? `${classValue} ${styles['container-textarea']} ${styles.disabled}` : `${classValue} ${styles['container-textarea']}`;
  }

  useImperativeHandle(ref, () => ({
    getValue: () => textareaValue,
    getObject: () => ({ [name]: textareaValue }),
    getElement: () => ref.current,
  }));

  useEffect(() => {
    setTextareaValue(textareaWatch);
  }, [textareaWatch]);

  useEffect(() => {
    autosize();
  }, []);

  return (
    <div
      ref={textareaRef}
      className={getClassName()}
    >
      { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({
          field: {
            // name,
            value = '',
            // ref,
            onChange,
          },
          formState: { isValid },
        }) => {
          function handleChangeValue(val) {
            if (!isValid && val === '\n') {
              onChange('');
              return null;
            }
            onChange(val);
            autosize();
            return null;
          }

          return (
            <div className={`${styles.container}`}>
              <textarea
                // CONTROLLER -> FIELD PROPS
                name={name}
                ref={ref}
                //
                value={value}
                className={disabled ? `${styles.textarea} ${styles.disabled}` : styles.textarea}
                placeholder={placeholder}
                maxLength={maxLength}
                rows='8'
                style={style}
                onChange={(e) => {
                  handleChangeValue(e.target.value);
                } }
              >
              </textarea>
              {(maxLength)
                && <p className={styles['count-characters']}>
                  {`${value ? value.length : 0} / ${maxLength}`}
                </p>
              }
            </div>
          );
        }

        }
      />
    </div>

  );
});

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  maxLength: PropTypes.number,
};

export default Textarea;
