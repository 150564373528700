import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FaStop } from 'react-icons/fa';
import { BsMusicNoteBeamed, BsImageFill } from 'react-icons/bs';
import { getTypeSupport } from '../../helpers';
import { putFeedbackQuestion } from '../../actions/quiz';
import styles from './card-quiz.module.scss';
import thumbup from './thumbup.svg';
import thumbdown from './thumbdown.svg';
import bonusStar from '../../assets/images/icons/bonus-star.svg';
// import { MediaYoutube } from '../Media';
import ModalMedia from '../ModalMedia';
import AnimatedButton from '../AnimatedButton';
import play from '../../assets/images/icons/play2.svg';

const CardQuiz = ({
  // index = 1,
  id,
  active = false,
  points = '',
  indexSerie = 0,
  indice = null,
  question = '',
  difficulty = 0,
  rubrique = 'guerres',
  answer = {},
  media = {},
  fnLaunchMediaOnRemote,
  fnStopMediaOnRemote,
}) => {
  const [isReversed, setIsReversed] = useState(false);
  const modalMediaRef = useRef();
  function handleReverse() {
    if (active) setIsReversed(!isReversed);
  }

  function handlePlayMedia(e) {
    if (e) e.stopPropagation();
    modalMediaRef.current.open();
    if (typeof fnLaunchMediaOnRemote === 'function') {
      fnLaunchMediaOnRemote();
    }
  }

  function handleStopMedia(e) {
    e.stopPropagation();
    if (typeof fnStopMediaOnRemote === 'function') {
      fnStopMediaOnRemote();
    }
  }

  return (
    <>
      {(media.source) && (
        <ModalMedia
          autoPlay
          modalRef={modalMediaRef}
          questionType={getTypeSupport(media.type_support)}
          type={media.type}
          source={media.source}
        />
      )}
      <div className={styles.containerCard}>
        <div className={isReversed ? `${styles.card}  ${styles.reverse}` : styles.card} onClick={(e) => handleReverse(e)}>
          <div className={active ? styles.container : `${styles.container} ${styles.disabled}`}>
            <div className={styles.front}>
              {indice
                ? <div className={styles['container-indice']}>
                  <div>
                    <img src={bonusStar} alt="bonus" />
                    <h4 className={styles[`serie-${indexSerie + 1}`]}>
                      Indice bonus {indexSerie + 1}
                    </h4>
                    <p className={styles.answer}>
                      {indice}
                    </p>
                  </div>
                  <p>Toucher la carte pour la retourner</p>
                </div>
                : <>
                  <div className={styles.header}>
                    <span className={styles['container-left']}>
                      <p className={`${styles['sub-thematic']} ${styles[`serie-${indexSerie + 1}`]}`}>{rubrique}</p>
                      <p className={styles.difficulty}>
                        difficulté <span className="bold">{difficulty}/5</span>
                      </p>
                    </span>
                    <span className={styles['container-right']}>
                      <p className={`${styles.point} ${styles[`serie-${indexSerie + 1}`]}`}><span className="bold">{points}</span>pts</p>
                    </span>
                  </div>
                  <div>
                    {(media.source && media.type === 'video') && (
                      <div className={styles['container-media']}>
                        <div className={styles['media-controls']}>
                          <button onClick={(e) => handlePlayMedia(e)}>
                            {getTypeSupport(media.type_support) === 'audio'
                              ? <BsMusicNoteBeamed />
                              : <img src={play} alt="démarrer le média" />
                            }
                          </button>
                          <button onClick={(e) => handleStopMedia(e)} ><FaStop size={'20px'} /></button>
                        </div>
                      </div>
                    )}
                    {(media.source && media.type === 'audio') && (
                      <div className={styles['container-media']}>
                        <div className={styles['media-controls']}>
                          <button onClick={(e) => handlePlayMedia(e)}><BsMusicNoteBeamed /></button>
                          <button onClick={(e) => handleStopMedia(e)} ><FaStop size={'20px'} /></button>
                        </div>
                      </div>
                    )}
                    {(media.source && media.type === 'image') && (
                      <div className={styles['container-media']}>
                        <div className={styles['media-controls']}>
                          <button onClick={(e) => handlePlayMedia(e)}><BsImageFill size="20px" /></button>
                          <button onClick={(e) => handleStopMedia(e)} ><FaStop size={'20px'} /></button>
                        </div>
                      </div>
                    )}
                    <p className={styles.question}>{question}</p>
                  </div>
                  <FooterCard id={id} />
                </>
              }
            </div>
            <div className={styles.back}>
              {!indice ? (
                <>
                  <div className={styles['container-answer']}>
                    <div>
                      <h4 className={styles.title}>
                        Réponse
                      </h4>
                      <p className={styles.answer}>
                        {answer.text}
                      </p>
                    </div>
                  </div>
                  {answer.detail && (
                    <div className={styles['container-detail']}>
                      <h4 className={styles['title-detail']}>
                        Informations complémentaires
                      </h4>
                      <p>{answer.detail}</p>
                    </div>
                  )}
                </>
              ) : <div className={`${styles['container-detail']} ${styles['detail-indice']}`}>
                <div>
                  <h4 className={`${styles[`serie-${indexSerie + 1}`]} ${styles.title}`}>
                    Réponse bonus
                  </h4>
                  <p className={styles.answer}>
                    {answer.text}
                  </p>
                </div>
                {answer.detail && (
                  <>
                    <h4 className={styles['title-detail']}>
                      Informations complémentaires
                    </h4>
                    <p>{answer.detail}</p>
                  </>
                )}
              </div>
              }
              <p>Toucher la carte pour la retourner</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FooterCard = ({ id }) => {
  const dispatch = useDispatch();
  const thumbUp = useRef();
  const thumbDown = useRef();

  function handleLike(e, like) {
    e.stopPropagation();
    if (like) {
      thumbUp.current.trigger();
    } else {
      thumbDown.current.trigger();
    }
    const obj = {
      id_question: id,
      flag_feedback_plus: like ? 1 : 0,
      flag_feedback_moins: like ? 0 : 1,
      id_reload_type: 0,
    };
    putFeedbackQuestion(dispatch, obj);
  }

  return (
    <div className={styles.bottom}>
      <AnimatedButton intensity='hard' ref={thumbDown}><img src={thumbdown} onClick={(e) => handleLike(e, false)} kalt="thumb down" /></AnimatedButton>
      <p>Toucher la carte pour la retourner</p>
      <span>
        <AnimatedButton intensity='hard' ref={thumbUp}><img src={thumbup} onClick={(e) => handleLike(e, true)} kalt="thumb up" /></AnimatedButton>
      </span>
    </div>
  );
};

CardQuiz.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  points: PropTypes.string,
  indexSerie: PropTypes.number,
  indice: PropTypes.string,
  question: PropTypes.string,
  difficulty: PropTypes.number,
  rubrique: PropTypes.string,
  answer: PropTypes.object,
  media: PropTypes.object,
  active: PropTypes.bool,
};

export default CardQuiz;
