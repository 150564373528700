import React from 'react';
import { useForm } from 'react-hook-form';
import frLocale from 'date-fns/locale/fr';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { putSession, sendWsEvent } from '../../actions/quiz';
import {
  InputText,
  // InputFormatNumber,
  MuiMobileDatePicker,
  MuiMobileTimePicker,
} from '../../lib/HooksFormFields';

import LoaderSpinner from '../LoaderSpinner';
import ModalStandalone from '../../lib/ModalStandalone';
import styles from './modal-next-event.module.scss';

// REGEX DATE WITH YEAR
// /^(0[0-9]|1[0-9]|2[0-9]|3[0-1])\/(0[0-9]|1[0-2])\/(\d{2,2})$/

const ModalNextEvent = ({ modalRef, defaultNextEvent }) => {
  const dispatch = useDispatch();
  const quizReducer = useSelector((state) => state.quizReducer);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    const obj = {
      diffusion_evenement_nom: data.diffusion_evenement_nom,
      diffusion_evenement_heure: format(data.diffusion_evenement_heure, 'HH:mm', { locale: frLocale }),
      diffusion_evenement_date: format(data.diffusion_evenement_date, 'dd/MM/yy', { locale: frLocale }),
    };
    putSession(dispatch, obj).then(() => {
      sendWsEvent({
        action: 'fetch_session',
      });
      modalRef.current.close();
    });
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
      handleSubmit(onSubmit)();
    }
  }

  function renderErrors() {
    let messageError;
    if (Object.keys(errors).length > 0) {
      const firstError = errors[Object.keys(errors)[0]];
      messageError = <p className={styles.errors}>{firstError.message}</p>;
    }
    return messageError;
  }

  function strToDate(strDate, formatDate) {
    let date = null;
    if (strDate) {
      date = parse(strDate, formatDate, new Date());
    }
    return date;
  }
  return (
    <ModalStandalone ref={modalRef}>
      <div className={styles.modal}>
        <h3>Prochain événement</h3>
        <form onKeyDown={handlePressEnter}>
          <div className={styles.field}>
            <InputText
              control={control}
              defaultValue={defaultNextEvent?.diffusion_evenement_nom}
              name='diffusion_evenement_nom'
              label='Titre'
              rules={{
                required: 'Vous devez indiquez le nom du prochaine événement.',
              }}
            />
          </div>
          <div className={styles.date}>
            <div className={styles.field}>
              {strToDate()}
              <MuiMobileDatePicker
                label='Date'
                name="diffusion_evenement_date"
                defaultValue={
                  strToDate(defaultNextEvent?.diffusion_evenement_date, 'dd/MM/yy')
                }
                control={control}
                placeholder="Selectionnez une date"
                rules={{ required: 'Indiquer la date...' }}
              />
            </div>
            <div className={styles.field}>
              <MuiMobileTimePicker
                label='Heure'
                name="diffusion_evenement_heure"
                defaultValue={
                  strToDate(defaultNextEvent?.diffusion_evenement_heure, 'HH:mm')
                }
                control={control}
                placeholder="Selectionnez une date"
                rules={{ required: 'Indiquer la date...' }}
              />
            </div>
          </div>
          {renderErrors()}
          <div className='center'>
            <button type='button' className='secondary' onClick={handleSubmit(onSubmit)}>
              <span>Valider</span>
              {quizReducer.loadingType === 'set_session' && <LoaderSpinner size='sm' white />}
            </button>
          </div>
        </form>
      </div>
    </ModalStandalone>
  );
};

export default ModalNextEvent;
