import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { FaPlay } from 'react-icons/fa';
import useToggleVirtualKeyboard from '../../hooks/useToggleVirtualKeyboard';
import { isTooLate } from '../../helpers';
import styles from './bottombar.module.scss';

const BottomBar = () => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const history = useHistory();
  const [hide, setHide] = useState(null);
  const location = useLocation();
  const domNode = document.getElementById('bottombar');
  const toggleVirtual = useToggleVirtualKeyboard(
    () => setHide(true),
    () => {
      if (
        !location.pathname.startsWith('/app/quiz')
        && !location.pathname.startsWith('/app/result')
        && !location.pathname.startsWith('/app/teams')
        && !location.pathname.startsWith('/app/configuration')
        && location.pathname !== '/app/feedback'
      ) {
        setHide(false);
      }
    },
  );

  const { bottom, transform } = useSpring({
    to: {
      bottom: `${hide ? -100 : 0}px`,
      transform: `scale(${hide ? 0 : 1}) translate(0,0%)`,
    },
    from: {
      bottom: '-100px',
      transform: 'scale(0) translate(0,0%)',
    },
  });

  useEffect(() => {
    // console.log(navigator.userAgent);
    toggleVirtual.update();
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.startsWith('/app/quiz')
      || location.pathname.startsWith('/app/teams')
      || location.pathname.startsWith('/app/configuration')
      || location.pathname.startsWith('/app/review')
      || location.pathname.startsWith('/app/result')
      || location.pathname === '/app/feedback'
    ) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [location]);

  function launchQuizOnRemote() {
    const tooLate = isTooLate(quizReducer.session);

    if (tooLate) {
      history.push('/app/result/teams');
    } else {
      history.push('/app/quiz/0');
    }
  }

  function getStyle() {
    const style = {};
    if (hide) {
      style.display = 'none';
    } else {
      style.bottom = bottom;
    }
    style.justifyContent = 'center';
    return style;
  }

  return createPortal(
    <animated.div
      className={styles.bottombar}
      style={getStyle()}
    >
      <animated.div
        className={styles['play-button']}
        style={{ transform }}
      >
        <a onClick={() => launchQuizOnRemote()}>
          {isTooLate(quizReducer.session)
            ? <p>Classements</p>
            : (<><p>Démarrer l&apos;animation</p><div className={styles.icon}><FaPlay/></div></>)
          }
        </a>
      </animated.div>
    </animated.div>,
    domNode,
  );
};

export default BottomBar;
