import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IoChevronUp, IoChevronDown } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { sendWsEvent } from '../../../actions/quiz';
import Conseil from '../../../components/Conseil';
import Ranking from '../../../components/Ranking';
import styles from './ranking-teams.module.scss';
import { getDate } from '../../../helpers';

const RankingTeams = () => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const { teams, session } = quizReducer;
  const topThree = (teams || []).sort((a, b) => b.score - a.score).slice(0, 3);
  const [sort, setSort] = useState('asc');

  function launchRankingOnRemote() {
    sendWsEvent({
      path: 'results',
    });
  }

  return (
    <div className={styles['ranking-teams']}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <h2>Cotton quiz</h2>
            <p >
              <span className={styles.date}>{getDate(session.date)}</span><span className='bold'> {session.heure_debut}</span> <br />
              {session.client.nom}, {session.client.ville}
            </p>
          </div>
          <Conseil containerClassName={styles.advices} zoningCode='resultats_classement_J1' />
          <div className={styles['container-button']}>
            {teams && (
              <button onClick={() => setSort((state) => (state === 'asc' ? 'desc' : 'asc'))} className={`${styles.btnSort} secondary`} >
                <IoChevronUp />
                <IoChevronDown />
              </button>
            )}
            <NavLink to='/app/result/teams' onClick={() => launchRankingOnRemote()}>
              <button className="secondary" >scores</button>
            </NavLink>
          </div>
        </div>
        <Ranking teams={topThree} sort={sort} />
      </div>
    </div>
  );
};

export default RankingTeams;
