import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from 'react';
import { v4 as uuid } from 'uuid';
import { orderBy, cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  putTeamsScore,
} from '../../../actions/quiz';
import { prepareScoreForPost } from '../../../helpers';
import styles from './list-teams.module.scss';
import ItemTeam from '../ItemTeam';

const ListTeams = forwardRef(({
  teams = [],
  editableScores = true,
  indexSerie = null,
  hideScore = true,
  sort = 'asc',
  noScroll = false,
}, ref) => {
  const dispatch = useDispatch();
  const listTeamsRef = useRef();
  const timersRef = useRef(new Map());
  const [teamsArr, setTeamsArr] = useState(teams);
  const [disabledInput, setDisabledInput] = useState([]);

  useEffect(() => {
    const initDisabledInputs = teams.map((d) => {
      const targetScore = typeof indexSerie === 'number' ? d.series[indexSerie].score : d.score;
      return targetScore > -1 ? d.id : null;
    }).filter((d) => d);
    setDisabledInput(initDisabledInputs);
    setTeamsArr(teams);
  }, [teams]);

  useImperativeHandle(ref, () => ({
    getTeams: () => teamsArr,
  }));

  // handleChangeScore(team.id, value)
  function handleChangeScore(teamId, score) {
    if (!editableScores) return;
    let arr = cloneDeep(teamsArr);
    const team = arr.find((d) => d.id === teamId);
    const prevTeam = JSON.parse(JSON.stringify(team));
    if (typeof indexSerie !== 'number') {
      team.score = parseInt(score, 10);
      team.bonus = -1;
      team.series = team.series.map(() => ({ score: -1 }));
    } else if (typeof indexSerie === 'number') {
      team.series[indexSerie].score = parseInt(score, 10);
      if (team.series[indexSerie].score > 0) {
        let total = team.series.reduce((t, cur) => t + cur.score, 0);
        if (Number.isNaN(total)) total = 0;
        if (team.bonus && team.bonus > 0) total += team.bonus;
        team.score = total;
      }
    }

    // eslint-disable-next-line no-confusing-arrow
    arr = arr.map((t) => t.id === teamId ? team : t);
    setTeamsArr(arr);

    const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);
    const prevScores = [...prevTeam.series.map((d) => (d.score || 0)), prevTeam.score || 0];
    const newScores = [...team.series.map((d) => (d.score || 0)), team.score || 0];
    const scoresEquals = equals(prevScores, newScores);

    // auto save happen here
    if (timersRef.current.get(teamId)) {
      clearTimeout(timersRef.current.get(teamId));
      timersRef.current.set(teamId, null);
    }
    if (
      score !== null && !scoresEquals
    ) {
      const timer = setTimeout(() => {
        const s = arr.find((d) => d.id === teamId);
        const s2 = prepareScoreForPost([s])[0];
        putTeamsScore(dispatch, [s2]).then(() => {
          document.activeElement.blur();
          setDisabledInput((state) => [...state, teamId]);
        });
      }, 2000);
      timersRef.current.set(teamId, timer);
    }
  }

  function getScore(team) {
    let score = null;
    if (!hideScore && typeof indexSerie !== 'number') {
      score = team.score;
    } else if (!hideScore) {
      score = team.series[indexSerie].score;
    }
    return score;
  }

  function getBonus(bonus) {
    let res = null;
    if (typeof bonus === 'number' && bonus === -1) {
      res = null;
    } else if (typeof bonus === 'number' && bonus === 0) {
      res = false;
    } else if (typeof bonus === 'number' && bonus > 0) {
      res = true;
    }
    return res;
  }

  function isTotalEditable(series, bonus) {
    return series.every((d) => typeof d.score === 'number' && d.score === 0) && bonus <= 0;
  }

  function getLiWithSeparator() {
    const key = typeof indexSerie === 'number' ? `points.equipe_quiz_serie_${indexSerie + 1}_points` : 'points.equipe_quiz_points';
    let sortedTeams = teamsArr;
    sortedTeams = orderBy(teamsArr, [key, 'nom'], ['desc', 'asc']);
    if (sort === 'desc') sortedTeams.reverse();
    const elts = [];
    sortedTeams.forEach((team, i) => {
      const curLetter = team.nom.slice(0, 1).toLowerCase();
      const prevLetter = teamsArr[i - 1]?.nom.slice(0, 1).toLowerCase();
      if (prevLetter !== curLetter) {
        elts.push(
          <li id={`separator-${curLetter.toUpperCase()}`} key={`separator-${curLetter}-${uuid()}`}></li>,
        );
      }
      elts.push(
        <li className={typeof indexSerie !== 'number' ? `${styles.itemTeam} ${styles.withIndex}` : styles.itemTeam} key={`team-${team.id}-${indexSerie}`} style={team.hide ? { display: 'none' } : {}}>
          {typeof indexSerie !== 'number' && (
            <span className={styles.index}>{sort === 'desc' ? (teamsArr.length) - i : (i + 1)}</span>
          )}
          <ItemTeam
            key={`item-team-${team.id}-${indexSerie}`}
            name={team.nom}
            bonus={getBonus(team.bonus)}
            score={getScore(team)}
            indexSerie={indexSerie}
            team={team} // the whole team is needed to submit bonus answer
            disabledInput={disabledInput.includes(team.id)}
            setEnabledInput={() => setDisabledInput((state) => state.filter((d) => d !== team.id))}
            handleChangeScore={
              ((editableScores)
                || (editableScores && isTotalEditable(team.series, team.bonus)))
                ? (value) => handleChangeScore(team.id, value)
                : null
            }
          >
          </ItemTeam>
        </li>,
      );
    });
    return elts;
  }

  return (
    <div
      className={noScroll ? `${styles.teams} ${styles.noScroll}` : styles.teams}
    >
      <ul
        ref={listTeamsRef}
        style={noScroll ? { overflow: 'visible' } : {}}
      >
        {getLiWithSeparator()}
      </ul>
    </div>
  );
});

export default React.memo(ListTeams);
