import React from 'react';
import YouTube from 'react-youtube';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styles from './media-youtube.module.scss';
import { getVideoId } from '../../../helpers';

const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring);
  const obj = {};

  // iterate over all keys
  Array.from(params.keys()).forEach((key) => {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  });

  return obj;
};

const MediaYoutube = ({ source, autoPlay = false, handleClick = null }) => {
  const [url, searchString] = source.split('?');
  const searchParams = parseParams(searchString || '');
  function _handleClick(e) {
    if (!handleClick) return;
    e.stopPropagation();
    handleClick();
  }

  function getSearchParams() {
    const obj = { ...searchParams };
    delete obj.v;
    return obj;
  }

  // OK modestbranding : 0
  // OK rel: 0
  // OK Mute: 0
  // OK start
  // OK end
  // cc_load_policy fonction en ajoutant cc_lang_pref=en
  // et ne fonctionne pas sur les sous-titres auto

  // autoplay not working
  // showinfo not working AS3 player
  // loop not working AS3 player
  return (
    <>
      <div className={styles.video} onClick={(e) => _handleClick(e)}>
        <span style={handleClick ? { pointerEvents: 'none' } : {}}>
          <YouTube
            videoId={searchParams.v ? searchParams.v : getVideoId(url)}
            onReady={(e) => {
              e.target.unMute();
              if (autoPlay) {
                e.target.playVideo();
              }
            }}
            opts={{
              playerVars: isEmpty(getSearchParams())
                ? {
                  // https://developers.google.com/youtube/player_parameters
                  autoPlay: 1,
                  modestbranding: 1,
                  frameBorder: 1,
                  rel: 0,
                  mute: 0,
                  controls: 2,
                }
                : getSearchParams(),
            }}
          />
        </span>
      </div>
    </>
  );
};

MediaYoutube.propTypes = {
  handleClick: PropTypes.func,
  source: PropTypes.string.isRequired,
  openInModal: PropTypes.bool,
};

export default MediaYoutube;
