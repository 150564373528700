import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import ResultTeams from './result-teams';
import RankingTeams from './ranking-teams';

const Result = () => {
  useEffect(() => {
    // console.log('test');
  }, []);

  return (
    <Switch>
      <Route exact path="/app/result/teams">
        <ResultTeams />
      </Route>
      <Route exact path="/app/result/ranking">
        <RankingTeams />
      </Route>
    </Switch>
  );
};

export default Result;
