import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import frLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Toolbar } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/lab';

import styles from './datepicker.module.scss';

const color = '#00A8E8';
const labelColor = '#2F304E';

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '.PrivatePickersYear-root button': {
            '&.Mui-selected': {
              backgroundColor: `${color} !important`,
              '&:hover': {
                backgroundColor: color,
              },
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: color,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${color} !important`,
            '&:hover': {
              backgroundColor: color,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          backgroundColor: 'transparent',
          color: labelColor,
        },
      },
    },
  },
});

function getDatePickerHeaderText(date) {
  return format(date, 'd MMMM yyyy', { locale: frLocale });
}

const MuiMobileDatePicker = ({
  name,
  control,
  rules,
  defaultValue = null,
  label = null,
}) => (
  <div className={styles['container-input']}>
    {label && <label className={styles.label}>{label}</label>}
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {
          // name,
          value,
          onChange,
        },
      }) => {
        const [date, setDate] = useState(defaultValue);

        function handleChange(d) {
          setDate(d);
        }

        // handle setValue
        useEffect(() => {
          if (typeof value?.getTime() === 'number' && (value?.getTime() !== date?.getTime())) {
            setDate(value);
          }
        }, [value]);

        useEffect(() => {
          onChange(date);
        }, [date]);

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <ThemeProvider theme={theme}>
              <MobileDatePicker
                value={date}
                orientation='portrait'
                onChange={handleChange}
                ToolbarComponent={(props) => (
                  <Toolbar>
                    <div className={styles.toolbar}>{props.date
                      ? <p>{getDatePickerHeaderText(props.date)}</p>
                      : <p>{getDatePickerHeaderText(new Date())}</p>
                    }</div>
                  </Toolbar>
                )}
                renderInput={(params) => (
                  <input className={styles.input} {...params.inputProps} />
                )}
                cancelText={'Annuler'}
                okText={'Valider'}
                clearText={'Effacer'}
              />
            </ThemeProvider>
          </LocalizationProvider>
        );
      }}
    />
  </div>
);
// <TextField {...params} />
// <input className={styles.input} {...params.inputProps} />
export default MuiMobileDatePicker;
