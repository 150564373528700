import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import frLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Toolbar } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  LocalizationProvider,
  MobileTimePicker,
} from '@mui/lab';

import styles from './timepicker.module.scss';

const color = '#00A8E8';
const labelColor = '#2F304E';

const theme = createTheme({
  palette: {
    primary: {
      main: color,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: color,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: color,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: labelColor,
        },
      },
    },
  },
});

function getDatePickerHeaderText(date) {
  return format(date, 'HH:mm', { locale: frLocale });
}

const MuiMobileDatePicker = ({
  name,
  control,
  rules,
  defaultValue = new Date(),
  label = null,
}) => (
  <div className={styles['container-input']}>
    {label && <label className={styles.label}>{label}</label>}
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {
          // name,
          value,
          onChange,
        },
      }) => {
        const [date, setDate] = useState(defaultValue);

        function handleChange(d) {
          setDate(d);
        }

        // handle setValue
        useEffect(() => {
          if (typeof value?.getTime() === 'number' && (value?.getTime() !== date?.getTime())) {
            setDate(value);
          }
        }, [value]);

        useEffect(() => {
          onChange(date);
        }, [date]);

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <ThemeProvider theme={theme}>
              <MobileTimePicker
                label="Basic example"
                value={date}
                clearable
                onChange={handleChange}
                ToolbarComponent={(props) => (
                  <Toolbar>
                    <div className={styles.toolbar}>{props.date
                      ? <>
                        <p className={styles.small}>Prochaine événement à</p>
                        <p>
                          {getDatePickerHeaderText(props.date)}
                        </p>
                      </>
                      : <p >Sélectionner une heure</p>
                    }</div>
                  </Toolbar>
                )}
                renderInput={(params) => (
                  <input className={styles.input} {...params.inputProps} />
                )}
                cancelText={'Annuler'}
                okText={'Valider'}
                clearText={'Effacer'}
              />
            </ThemeProvider>
          </LocalizationProvider>
        );
      }}
    />
  </div>
);
// <TextField {...params} />
// <input className={styles.input} {...params.inputProps} />
export default MuiMobileDatePicker;
