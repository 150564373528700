import { cloneDeep } from 'lodash';
import { prepareTeamsForApp, updateQuestionPropsInSeries } from '../helpers';

import {
  GET_QUIZ_SERIES,
  RELOAD_QUIZ_SERIES,
  PUT_CLIENT_HELP,
  PUT_SCORES,
  PUT_SESSION,
  UPADTE_TEAM,
  UPADTE_TEAM_2,
  ADD_TEAM,
  PUT_FEEDBACK_QUESTION,
  SET_LOADING_TYPE,
  CREATE_TEAM_LOADING,
  QUIZ_LOADING,
  ERROR_QUIZ,
} from '../actions/types.js';

const initialState = {
  series: null,
  session: null,
  teams: null,
  advices: null,
  loadingType: null,
  isLoading: null,
  createTeamLoading: false,
  error: null,
  showAdvices: false,
};

let updatedTeams;
let updatedSeries;
let updatedSession;

const quizz = (state = initialState, action) => {
  let updatedState = {};
  switch (action.type) {
    case GET_QUIZ_SERIES:

      // action.payload.series.forEach((serie, i) => {
      //   console.log(`____ série ${i}___`);
      //   serie.questions.forEach((question) => {
      //     if (question.lien_support_reponse) {
      //       console.log(question);
      //     }
      //     if (/.mp3/.test(question.lien_support) || /.mp3/.test(question.lien_support_reponse)) {
      //       console.log(question);
      //     }
      //     if (/.jpg/.test(question.lien_support) || /.png/.test(question.lien_support_reponse)
      //       || /.jpeg/.test(question.lien_support)) {
      //       console.log(question);
      //     }
      //   });
      // });

      updatedState = {
        ...state,
        teams: prepareTeamsForApp(action.payload.teams),
        series: action.payload.series,
        session: action.payload.session,
        advices: action.payload.advices,
        isLoading: false,
        showAdvices: !!action.payload.session.client.flag_espace_client_aide,
      };
      break;
    case RELOAD_QUIZ_SERIES:
      updatedState = {
        ...state,
        series: action.payload,
      };
      break;
    case PUT_SCORES:
      updatedTeams = [];
      state.teams.forEach((team) => {
        let cloneTeam = Object.assign(team, {});
        const find = action.payload.find((d) => d.id === cloneTeam.id);
        if (find) {
          cloneTeam.points = find.points;
          [cloneTeam] = prepareTeamsForApp([cloneTeam]);
        }
        updatedTeams.push(cloneTeam);
      });
      updatedState = { ...state, teams: updatedTeams, loadingType: null };
      break;
    case PUT_SESSION:
      updatedSession = Object.assign(state.session, action.payload);
      updatedState = { ...state, session: updatedSession, loadingType: null };
      break;
    case PUT_FEEDBACK_QUESTION:

      updatedSeries = updateQuestionPropsInSeries(state.series, {
        flag_feedback_moins: action.payload.flag_feedback_moins,
        flag_feedback_plus: action.payload.flag_feedback_plus,
      }, action.payload.id_question);

      updatedState = { ...state, series: updatedSeries };
      break;

    case UPADTE_TEAM:
      updatedTeams = [];
      state.teams.forEach((team) => {
        let cloneTeam = Object.assign(team, {});
        const find = action.payload.find((d) => d.id === cloneTeam.id);
        if (find) cloneTeam = find;
        updatedTeams.push(cloneTeam);
      });
      updatedState = { ...state, teams: prepareTeamsForApp(updatedTeams) };
      break;
    case UPADTE_TEAM_2:
      updatedState = { ...state, teams: action.payload };
      break;
    case SET_LOADING_TYPE:
      updatedState = { ...state, loadingType: action.payload };
      break;
    case ADD_TEAM:
      updatedTeams = cloneDeep(state.teams);
      // eslint-disable-next-line no-case-declarations
      const index = updatedTeams.findIndex((d) => action.payload.id === d.id);
      if (index < 0) {
        updatedTeams.push(action.payload);
      } else {
        updatedTeams[index] = action.payload;
      }
      updatedState = {
        ...state,
        teams: prepareTeamsForApp(updatedTeams),
        createTeamLoading: false,
      };
      break;
    case QUIZ_LOADING:
      updatedState = { ...state, isLoading: true };
      break;
    case CREATE_TEAM_LOADING:
      updatedState = { ...state, createTeamLoading: true };
      break;
    case PUT_CLIENT_HELP:
      updatedState = { ...state, showAdvices: !state.showAdvices };
      break;
    case ERROR_QUIZ:
      updatedState = {
        ...state,
        isLoading: false,
        series: null,
        error: action.payload,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default quizz;
