import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

import { API_URL } from '../../../constant';
import LoaderSpinner from '../../../components/LoaderSpinner';
import styles from '../help.module.scss';

const FAQ = () => {
  const history = useHistory();
  const [content, setContent] = useState();

  useEffect(() => {
    async function getContent() {
      const token = localStorage.getItem('token');
      if (token) {
        let res;
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };
        const url = `${API_URL}/quiz/faq`;
        const promise = await fetch(url, config);
        if (promise.ok) res = await promise.json();
        if (res?.faq) setContent(res.faq);
      }
    }
    getContent();
  }, []);

  return (
    <div className={styles.faq}>
      {content ? (
        <>
          <div className={styles['header-desktop']}>
            <div className={styles.title}>
              <a onClick={() => history.goBack()}><BiArrowBack size={34} /></a>
              <h1>FAQ</h1>
            </div>
          </div>
          <ul>
            {content.map((d, i) => (
              <li key={`faq-${i}`}>
                <h4 dangerouslySetInnerHTML={{ __html: d.nom }} />
                <span dangerouslySetInnerHTML={{ __html: d.descriptif_long }} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className={styles.loader}><LoaderSpinner blue/></div>
      )}
    </div>
  );
};

export default FAQ;
