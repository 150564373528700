import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './input-email.module.scss';

const InputEmail = ({
  name,
  control,
  required = false,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder = '',
}) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: required ? 'Ce champs email est obligatoire' : false,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Veuillez renseigner une adresse email valide',
      },
    }}
    render={({
      field: {
        value = '', ref, onChange, onBlur,
      },
    }) => (
      <div className={inline ? `${styles['container-input-email']} ${styles.inline}` : `${styles['container-input-email']}`}>
        {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
        <input
          autoComplete='new-password'
          autofill='off'
          ref={ref}
          value={value}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          className={styles['input-email']}
          type="email"
          placeholder={placeholder}
        />
      </div>
    )}
  />

);

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputEmail;
