import React, {
  useRef, useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import styles from './textarea.module.scss';

const Textarea = forwardRef((
  {
    placeholder = '',
    maxlength = null,
    name,
  }, ref,
) => {
  const [textareaValue, setTextareaValue] = useState('');
  const [style, setStyle] = useState({});
  const textareaRef = useRef();

  function autosize() {
    const elt = textareaRef.current.querySelector('textarea');
    if (!elt) return;
    elt.style.cssText = 'height:auto; padding:0';
    elt.style.cssText = `height:${elt.scrollHeight}px`;
    setStyle({
      padding: '10px',
      height: `${elt.scrollHeight}px`,
    });
  }

  useImperativeHandle(ref, () => ({
    getValue: () => textareaValue,
    getObject: () => ({ [name]: textareaValue }),
    getElement: () => ref.current,
  }));

  useEffect(() => {
    autosize();
  }, []);

  function handleChangeValue(val) {
    setTextareaValue(val);
  }

  return (
    <div className={`${styles['container-textarea']}`} ref={textareaRef}>
      <textarea
        name={name}
        ref={ref}
        value={textareaValue}
        className={styles.input}
        placeholder={placeholder}
        maxLength={maxlength}
        rows='10'
        style={style}
        onChange={(e) => {
          handleChangeValue(e.target.value);
        } }
      >
      </textarea>
      {(maxlength)
        && <p className={styles['count-characters']}>
          {`${textareaValue ? textareaValue.length : 0} / ${maxlength}`}
        </p>
      }
    </div>
  );
});

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
};

export default Textarea;
