import React, { useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withWindowSize } from 'react-fns';
import { isTooLate } from '../../helpers';
import { ListTeams } from '../../components/Teams';
import Conseil from '../../components/Conseil';
import styles from './teams.module.scss';
import FormAddTeam from '../../components/FormAddTeam';

const Teams = ({ width }) => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const containerRef = useRef();

  function handleClickAnchor() {
    const { current: element } = containerRef;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  // equipes_liste
  return (
    <div className={styles.teams} ref={containerRef}>
      {isTooLate(quizReducer?.session) && <Redirect to='/app/result/teams' />}
      <div className={styles.container}>
        <Conseil containerClassName={styles['container-advices']} zoningCode='equipes_liste' />
        <p onClick={() => handleClickAnchor()} className={styles.anchor}>Ajouter une équipe</p>
        <div>
          <ListTeams
            noScroll={true}
            hideAlphabet={width < 1024}
            teams={quizReducer.teams}
            editableScores={false}
          />
        </div>
        <div className={styles.containerForm}>
          <FormAddTeam withScore={false} withBonus={false} />
        </div>
      </div>
    </div>
  );
};

export default withWindowSize(Teams);
