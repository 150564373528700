import { useState, useEffect } from 'react';

const useToggleVirtualKeyboard = (handleOpen, handleClose) => {
  const [timestamp, setTimestamp] = useState();

  useEffect(() => {
    const elts = document.querySelectorAll('input, textarea, [contenteditable=true]');
    const interval = 350;

    const handleResize = (ts) => {
      if (Date.now() - ts < interval) {
        handleOpen();
      }
    };

    const handleFocusShowKeyboard = () => {
      const ts = Date.now();
      window.onresize = () => handleResize(ts);
    };

    const handleBlur = (e) => {
      const editableTarget = e.relatedTarget?.getAttribute('contenteditable') === 'true';
      if (
        !e.relatedTarget
        && (!editableTarget
        || !['INPUT', 'TEXTEARA'].includes(e.relatedTarget.tagName))
      ) {
        handleClose();
        window.removeEventListener('resize', handleResize);
      }
    };

    Array.from(elts).forEach((elt) => {
      elt.addEventListener('focusin', handleFocusShowKeyboard);
      elt.addEventListener('blur', handleBlur);
    });

    return () => {
      elts.forEach((elt) => {
        elt.removeEventListener('focus', handleFocusShowKeyboard);
        elt.removeEventListener('blur', handleBlur);
        window.onresize = null;
      });
    };
  }, [timestamp]);

  return ({
    update: () => setTimestamp(Date.now()),
  });
};

export default useToggleVirtualKeyboard;
