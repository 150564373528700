import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { useDrag } from 'react-use-gesture';
import {
  useSpring,
  animated,
  to,
  config,
} from 'react-spring';
import styles from './slider-quiz.module.scss';
import backward from '../../assets/images/icons/backward.svg';
import forward from '../../assets/images/icons/forward.svg';
import { quizContext } from '../../pages/quiz/quizContext';

const round = (value, precision = 1) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const SliderQuiz = ({
  children,
  updateIndex,
  currentIndex,
  indexSerie,
  univers,
  stepsPause,
}, ref) => {
  const sliderRef = useRef();
  const [boundSlide, setBoundSlide] = useState();
  const { isQuickViewOpen } = useContext(quizContext);

  const [{ pos }, api] = useSpring(() => ({
    to: { pos: [0, 0] },
    config: config.default,
  }));

  function findClosest(arr, num) {
    return arr.sort((a, b) => Math.abs(b.middle - num) - Math.abs(a.middle - num)).pop();
  }

  function getIfTouchDevice() {
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    return supportsTouch;
  }

  const bind = useDrag(
    (props) => {
      const { movement, last, delta } = props;
      if (!getIfTouchDevice()) return;
      let x = movement[0] < 0 ? movement[0] : 0;
      x += delta[0] * 10;
      const y = movement[1];
      if (last) {
        const { width } = sliderRef.current.getBoundingClientRect();
        const middle = Math.abs(x) + width / 2;
        const closest = findClosest([...boundSlide], middle);
        updateIndex(closest.index);
        api.start({ pos: [-(closest.middle - (width / 2)), 0] });
      } else {
        api.start({ pos: [x, y] });
      }
    },
    {
      initial: () => pos.get(),
      useTouch: true,
      swipe: { velocity: [100, 100] },
      axis: 'x',
      bounds: () => {
        const lastChild = sliderRef.current.childNodes?.[sliderRef.current.childNodes.length - 1];
        let bounds = { // this is the default props of react-gesture
          top: -Infinity,
          bottom: Infinity,
          left: -Infinity,
          right: Infinity,
        };
        if (lastChild) {
          const { right } = lastChild.getBoundingClientRect();
          bounds = { left: -1 * (right - pos.get()[0]), right: 0 };
        }
        return bounds;
      },
    },
  );

  function initBoundSlide() {
    const arr = [];
    const { width, left } = sliderRef.current.getBoundingClientRect();
    sliderRef.current.childNodes.forEach((child, i) => {
      const target = child.childNodes[0];
      const childBound = child.getBoundingClientRect();
      const bound = target.getBoundingClientRect();
      // if they were horizontal scroll
      // we add it to get the init position
      const boundX = bound.x + Math.abs(pos.get()[0]);
      arr.push({
        index: i,
        middle: (boundX + (bound.width / 2)) - (window.innerWidth - width),
        left: childBound.x - left,
      });
    });
    if (currentIndex !== 0) {
      const target = arr[currentIndex];
      api.start({ pos: [-(target.middle - (width / 2)), 0] });
    }
    setBoundSlide(arr);
  }

  useLayoutEffect(() => {
    initBoundSlide();
  }, [
    window.innerWidth,
    window.innerHeight,
  ]);

  function goToSlide(index) {
    const target = boundSlide[index];
    const { width } = sliderRef.current.getBoundingClientRect();
    updateIndex(index);
    api.start({ pos: [-(target.middle - (width / 2)), 0] });
  }

  // HANDLE PRESS ARROW
  useEffect(() => {
    function handlePressKey(e) {
      if (e.target.tagName !== 'INPUT') {
        if (e.key === 'ArrowRight' && boundSlide[currentIndex + 1]) {
          goToSlide(currentIndex + 1);
        } else if (e.key === 'ArrowLeft' && boundSlide[currentIndex - 1]) {
          goToSlide(currentIndex - 1);
        }
      }
    }

    window.addEventListener('keydown', handlePressKey);

    return () => {
      window.removeEventListener('keydown', handlePressKey);
    };
  }, [currentIndex, boundSlide]);

  useImperativeHandle(ref, () => ({
    goToSlide,
    setBoundSlide: () => {
      // sliderRef.current.scrollLeft = 0;
      initBoundSlide();
    },
  }));

  function wrapChildren() {
    const filteredChildren = children.filter((child) => child.props.children !== false);
    function clone(child, i) {
      const active = currentIndex === undefined ? false : currentIndex === i;
      let elt;
      if (child.type === 'div') {
        elt = child;
      } else {
        elt = React.cloneElement(child, { active });
      }
      return elt;
    }

    function getClassname(i, key, nextKey) {
      let className = styles.slide;
      if (
        key === 'serie-end'
        || key === 'result-team'
        || key === 'photos-team'
      ) className += ` ${styles.large}`;
      if (nextKey === 'serie-end') className += ` ${styles.short}`;
      return className;
    }

    return (
      <>
        {filteredChildren.map((child, i) => (
          <div className={getClassname(i, child.key, filteredChildren[i + 1]?.key)} key={`child-${i}`}>
            {clone(child, i)}
          </div>
        ))}
      </>
    );
  }

  function shouldBeHide() {
    let className = styles['container-slider'];
    if (isQuickViewOpen && window.innerWidth < 1024) className += ` ${styles.hide}`;
    return className;
  }

  return (
    <div className={shouldBeHide()}>
      {!getIfTouchDevice() && (
        <>
          {(currentIndex >= 1 && !stepsPause.includes(currentIndex)) && (
            <img className={styles.prev} src={backward} onClick={() => goToSlide(currentIndex - 1)} alt="question précédente" />
          )}
          {(!stepsPause.includes(currentIndex)) && (
            <img onClick={() => goToSlide(currentIndex + 1)} className={styles.next} src={forward} alt="question suivante" />
          )}
        </>
      )}
      <div className={stepsPause.includes(currentIndex)
        ? `${styles.univers} ${styles.hide}` : styles.univers
      }>
        <span>{`Série ${indexSerie + 1}`}</span><br />
        {univers}
      </div>
      <animated.div ref={sliderRef} className={styles.slider}
        {...bind()}
        style={{
          x: to([pos], ([x]) => round(x)),
          touchAction: 'none',
        }}
      >
        {wrapChildren(children)}
      </animated.div>
    </div >
  );
};

export default forwardRef(SliderQuiz);
