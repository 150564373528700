import React, { useEffect, useRef } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './home.module.scss';
import { putClientHelp } from '../../actions/quiz';
import { getDate, isTooLate } from '../../helpers';
import { ListTeams } from '../../components/Teams';
import Conseil from '../../components/Conseil';
import FormAddTeam from '../../components/FormAddTeam';
import StyledSwitch from '../../components/StyledSwitch';
import imgTeams from '../../assets/images/icons/teams.svg';
import review from '../../assets/images/icons/review.svg';
import config from '../../assets/images/icons/config.svg';

import logo from '../../assets/images/logo-2.png';

const Home = () => {
  const dispatch = useDispatch();
  const switchRef = useRef();
  const quizReducer = useSelector((state) => state.quizReducer);
  const { session, showAdvices, teams } = quizReducer;
  const containerTeamRef = useRef();

  useEffect(() => {
    if (switchRef.current) switchRef.current.setValue(quizReducer.showAdvices);
  }, [switchRef.current]);

  function handleClickAnchor() {
    const { current: element } = containerTeamRef;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  return (
    <div className={styles.home}>
      {isTooLate(session) && <Redirect to='/app/result/teams' />}
      <div className={styles.container}>
        <div>
          <div className={styles['header-desktop']}>
            <img src={logo} alt="logo" />
            <h2>Bienvenue{'\u00A0'}!</h2>
          </div>
          <div className={styles['header-mobile']}>
            <div>
              <h2>Cotton quiz</h2>
              <p >
                <span className={styles.date}>{getDate(session.date)}</span><span className='bold'> {session.heure_debut}</span> <br />
                {session.client.nom}, {session.client.ville}
              </p>
            </div>
            <div>
              <p className={styles.tag}>
                {!!session.flag_session_privee && <>Privée<br /></>}
                {session.nom_evenement && <>{session.nom_evenement}<br /></>}
                {session.saison.nom}
              </p>
            </div>
          </div>
          {showAdvices && (
            <>
              <h3>Conseils d&apos;animation</h3>
              <Conseil zoningCode='home' containerClassName={styles['container-advices']} />
            </>
          )}
          <div className={styles['container-button']}>
            <NavLink to="/app/review">
              <button>
                <img src={review} alt='Consulter les équipes' />
                <p>Lire et vérifier<br />mon Quiz</p>
              </button>
            </NavLink>
            <NavLink to="/app/configuration">
              <button>
                <img src={config} alt='Consulter les équipes' />
                <p>Personnaliser mon Quiz</p>
              </button>
            </NavLink>
            <NavLink to="/app/teams">
              <button>
                {teams.length && <div className={styles.countTeams}><p>{teams.length}</p></div>}
                <img src={imgTeams} alt='Consulter les équipes' />
                <p>Voir / Ajouter<br />des équipes</p>
              </button>
            </NavLink>
            {quizReducer?.session?.bonus?.indice_0 && (
              <button className={styles.indice}>
                <div className={styles.btnHeader}><span>?</span><span>Web indice</span></div>
                <div className={styles['text-indice']}>
                  <p>{quizReducer?.session?.bonus?.indice_0}</p>
                </div>
              </button>
            )}
          </div>
          <div>
            {quizReducer?.session?.bonus?.indice_0 && (
              <div className={styles['container-bonus-desktop']}>
                <h3>Bonus</h3>
                <p className="disabled">Ne pas divulguer aux joueurs</p>
                <div className={styles.bonus}>
                  <div>
                    <p>?</p>
                  </div>
                  <p>{quizReducer?.session?.bonus?.indice_0}</p>
                </div>
              </div>
            )}
            <div className={styles['container-toggle']}>
              <p>
                Activer / Désactiver les conseils d’animation
              </p>
              <StyledSwitch
                ref={switchRef} handleChange={(bool) => putClientHelp(dispatch, bool)} />
            </div>
          </div>
        </div>
        <div className={styles['container-teams']} >
          <h3>{quizReducer.teams.length} équipe{quizReducer.teams.length > 1 && 's'}</h3>
          <p onClick={() => handleClickAnchor()} className={styles.anchor}>Ajouter une équipe</p>
          <div className={styles.scrollableContainer} ref={containerTeamRef}>
            <ListTeams
              hideAlphabet={true}
              teams={quizReducer.teams}
              editableScores={false}
              noScroll={true}
            />
            <div className={styles.containerForm}>
              <FormAddTeam withScore={false} withBonus={false} noShadow/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
