/* eslint-disable import/prefer-default-export */
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { LOCKTOMOROW } from '../constant';

export function getDate(date) {
  return format(new Date(date), 'd MMMM yyyy', { locale: fr });
}

export function convertArraySeriesScore(id, series, bonus, score) {
  const points = {};
  let total = 0;

  series.forEach((d, i) => {
    points[`equipe_quiz_serie_${i + 1}_points`] = d.score;
    total += d.score > 0 ? d.score : 0;
  });

  const isSeriesNull = series.every((d) => d.score <= 0);
  if (isSeriesNull) {
    total = score;
  }

  if (typeof bonus === 'number' && bonus > -1) {
    total += bonus;
  }

  return {
    id,
    points: {
      equipe_quiz_points: total,
      ...points,
      equipe_quiz_bonus_points: typeof bonus === 'number' ? bonus : -1,
    },
  };
}

export function prepareScoreForPost(teams) {
  const arr = [];

  teams.forEach((team) => {
    const objectScore = convertArraySeriesScore(team.id, team.series, team.bonus, team.score);
    arr.push(objectScore);
  });

  return arr;
}

export function convertPointsToSeriesScore(points) {
  const series = [];

  if (points) {
    let i = 1;
    while (typeof points[`equipe_quiz_serie_${i}_points`] === 'number') {
      series.push({
        score: points[`equipe_quiz_serie_${i}_points`],
      });
      i++;
    }
  }

  return {
    series,
    score: points.equipe_quiz_points,
    bonus: points.equipe_quiz_bonus_points,
  };
}

export function prepareTeamsForApp(teams) {
  const arr = [];

  teams.forEach((team) => {
    const result = convertPointsToSeriesScore(team.points);
    arr.push({
      ...team,
      ...result,
    });
  });

  return arr;
}

export function updateQuestionPropsInSeries(series, questionProps, questionId) {
  let indexSerie;
  let indexQuestion;
  const updatedSeries = cloneDeep(series);

  series.every((serie, i) => {
    let bool;
    const index = serie.questions.findIndex((question) => (
      question.id === questionId
    ));
    if (index >= 0) {
      indexQuestion = index;
      indexSerie = i;
      bool = false;
    } else {
      bool = true;
    }
    return bool;
  });

  updatedSeries[indexSerie].questions[indexQuestion] = Object.assign(
    updatedSeries[indexSerie].questions[indexQuestion],
    questionProps,
  );

  return updatedSeries;
}

export function getVideoId(src) {
  let idVideo;
  if (src.includes('https://youtu.be/')) {
    const split = src.split('/');
    idVideo = split[split.length - 1];
  } else if (src.includes('https://www.youtube.com/watch?v=')) {
    idVideo = src.replace('https://www.youtube.com/watch?v=', '');
  } else if (src.includes('https://www.youtube.com/embed/')) {
    idVideo = src.replace('https://www.youtube.com/embed/', '');
  }

  if (idVideo && idVideo.includes('&')) {
    const split = idVideo.split('&');
    [idVideo] = split;
  }
  return idVideo;
}

export function getMediaType(source) {
  let type;
  if (/youtu/.test(source)) type = 'video';
  else if (/.mp3/.test(source)) type = 'audio';
  else if (/.jpg/.test(source) || /.jpeg/.test(source) || /.png/.test(source)) type = 'image';
  return type;
}

export function isTooLate(session) {
  let tooLate = false;
  if (LOCKTOMOROW && window.location.search !== '?unlock=1') {
    const date = new Date(session.date);
    let limitDate = date;
    limitDate = new Date(limitDate.setDate(limitDate.getDate() + 1));
    limitDate.setHours(12);
    tooLate = limitDate.getTime() < Date.now();
  }
  return tooLate;
}

export function getTypeSupport(idTypeSupport) {
  let typeSupport = null;
  switch (idTypeSupport) {
    case 1:
      typeSupport = 'image';
      break;
    case 2:
      typeSupport = 'audio';
      break;
    case 3:
      typeSupport = 'video';
      break;
    default:
      typeSupport = 'video';
      break;
  }
  return typeSupport;
}

export function isDeviceMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
}
