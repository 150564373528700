import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { BsMusicNoteBeamed, BsImageFill } from 'react-icons/bs';
import { AiOutlineReload } from 'react-icons/ai';
import { FaStop } from 'react-icons/fa';
import ModalMedia from '../ModalMedia';
import ModalFeedback from '../ModalFeedback';
import styles from './card-preview.module.scss';
import play from '../../assets/images/icons/play2.svg';

const CardPreview = ({
  question = '',
  answer = '',
  commentaire = '',
  rubrique = '',
  difficulty = 0,
  points = '',
  indexSerie,
  idSerie,
  index = 0,
  questionType = null,
  responseType = null,
  media = {},
  mediaAnswer = {},
  optsFeeback = null,
  content = null, // the complete question object need for feedback
  fnLaunchMediaOnRemote = null,
  fnStopMediaOnRemote = null,
  fnLaunchMediaResponseOnRemote = null,
  fnStopMediaResponseOnRemote = null,
  isAnswer = false,
  canReload = false,
}) => {
  const modalReloadRef = useRef();
  const modalMediaRef = useRef();
  const modalMediaResponseRef = useRef();
  const [positionModal, setPositionModal] = useState({ top: '0px', left: '0px' });

  function handleOpenModal(e) {
    const {
      top, bottom, left, height,
    } = e.target.getBoundingClientRect();
    let center = {
      bottom: `${((window.innerHeight - bottom) - height / 2) - 3}px`,
      left: `${left}px`,
      below: true,
    };

    if (top < 290) {
      center = {
        top: `${(top - height / 2) + 2}px`,
        left: `${left}px`,
        below: false,
      };
    }

    setPositionModal(center);
    modalReloadRef.current.toggle();
  }

  function handleClickMedia(toggleMediaAnswer) {
    if (toggleMediaAnswer) {
      if (typeof fnLaunchMediaResponseOnRemote === 'function') {
        modalMediaResponseRef.current.open();
        fnLaunchMediaResponseOnRemote();
      } else {
        modalMediaResponseRef.current.open();
      }
    } else if (!toggleMediaAnswer) {
      if (typeof fnLaunchMediaOnRemote === 'function') {
        modalMediaRef.current.open();
        fnLaunchMediaOnRemote();
      } else {
        modalMediaRef.current.open();
      }
    }
  }

  function handleStopMedia() {
    if (typeof fnStopMediaOnRemote === 'function') {
      fnStopMediaOnRemote();
    } else if (typeof fnStopMediaResponseOnRemote === 'function') {
      fnStopMediaResponseOnRemote();
    }
  }

  function getClassNameAnswer() {
    let className = styles['container-answer'];
    // NOT ANSWER BUT WITH A MEDIA ANSWER
    if (!isAnswer && mediaAnswer.source && !fnStopMediaResponseOnRemote && !fnLaunchMediaResponseOnRemote) className += ` ${styles.withMediaAnswer}`;
    if (!isAnswer && mediaAnswer.source && fnStopMediaResponseOnRemote && fnLaunchMediaResponseOnRemote) className += ` ${styles.withMediaRemoteAnswer}`;
    return className;
  }

  return (
    <div className={canReload ? `${styles.card} ${styles.withReload}` : styles.card}>
      {(canReload && optsFeeback?.length > 0) && (
        <>
          <div
            className={`${styles.reload}
            ${styles[`serie-${indexSerie + 1}`]}`}
            onClick={(e) => handleOpenModal(e)}
          >
            <AiOutlineReload size={24} />
          </div>
          <ModalFeedback
            modalRef={modalReloadRef}
            positionModal={positionModal}
            idSerie={idSerie}
            question={content}
            opts={optsFeeback}
          />
        </>
      )}
      {(mediaAnswer.source) && (
        <ModalMedia
          autoPlay
          modalRef={modalMediaResponseRef}
          questionType={responseType}
          type={mediaAnswer.type}
          source={mediaAnswer.source}
        />
      )}
      {(media.source) && (
        <ModalMedia
          autoPlay
          modalRef={modalMediaRef}
          questionType={questionType}
          type={media.type}
          source={media.source}
        />
      )}
      <div className={styles.header}>
        <span className={styles['container-left']}>
          <span>
            <p className={`${styles.rubrique} ${styles[`serie-${indexSerie + 1}`]}`}>
              {rubrique}
            </p>
            <p className={styles.index}>
              {isAnswer ? 'R' : 'Q'}
              {index}
            </p>
          </span>
          <p className={styles.difficulty}>
            niv <span className="bold">{difficulty}/5</span>
          </p>
        </span>
        <span className={styles['container-right']}>
          <p className={`${styles.point} ${styles[`serie-${indexSerie + 1}`]}`}><span className="bold">{points}</span>pts</p>
        </span>
      </div>
      <div className={styles.content}>
        <span>
          {isAnswer
            && <>
              <div className={styles['answer-only']}>
                  {(mediaAnswer.source
                    && fnStopMediaResponseOnRemote
                    && fnLaunchMediaResponseOnRemote) && (
                      <div className={styles.media}>
                      <p className={`${styles[`serie-${indexSerie + 1}`]}`}>Support réponse</p>
                        <div className={`${styles['controls-media']} ${styles.mediaAnswer}`}>
                          <button className={`${styles[`serie-${indexSerie + 1}`]}`} onClick={() => handleClickMedia(true)}>
                            {(!responseType || responseType === 'video') && <img src={play} alt="play" />}
                            {responseType === 'audio' && <BsMusicNoteBeamed />}
                            {responseType === 'image' && <BsImageFill size="20px" />}
                          </button>
                          {fnStopMediaResponseOnRemote && <button className={`${styles[`serie-${indexSerie + 1}`]}`}><FaStop size={'17px'} onClick={(e) => handleStopMedia(e)} /></button>}
                        </div>
                      </div>
                  )}
                <p className={`${styles.answer}  ${styles.bold}`}>
                 {answer}
                </p>
                {commentaire && <p className={styles.comments}>{commentaire}</p>}
                <p className={styles.question}>{question}</p>
              </div>
            </>
          }
          {!isAnswer
            && <>
              <div className={styles['container-question']}>
                <p className={isAnswer ? `${styles.question}` : `${styles.question} ${styles.bold}`}>{question}</p>
                {(media.source && !fnStopMediaOnRemote && !fnLaunchMediaOnRemote) && (
                  < button className={`${styles[`serie-${indexSerie + 1}`]}`} onClick={() => handleClickMedia()}>
                    {(!questionType || questionType === 'video') && <img src={play} alt="play" />}
                    {questionType === 'audio' && <BsMusicNoteBeamed size="20px" />}
                    {questionType === 'image' && <BsImageFill size="20px" />}
                  </button>
                )}
              </div>
              {(media.source && fnStopMediaOnRemote && fnLaunchMediaOnRemote) && (
                <div className={styles['controls-media']}>
                  <button className={`${styles[`serie-${indexSerie + 1}`]}`} onClick={() => handleClickMedia()}>
                    {(!questionType || questionType === 'video') && <img src={play} alt="play" />}
                    {questionType === 'audio' && <BsMusicNoteBeamed />}
                    {questionType === 'image' && <BsImageFill size="20px" />}
                  </button>
                  {fnStopMediaOnRemote && <button className={`${styles[`serie-${indexSerie + 1}`]}`}><FaStop size={'17px'} onClick={(e) => handleStopMedia(e)} /></button>}
                </div>
              )}
              <div className={getClassNameAnswer()}>
                <div>
                  <p className={isAnswer ? `${styles.answer}  ${styles.bold}` : `${styles.answer}`}>
                    {answer}
                  </p>
                  {commentaire && <p className={styles.comments}>{commentaire}</p>}
                </div>
                {(mediaAnswer.source
                  && !fnStopMediaResponseOnRemote
                  && !fnLaunchMediaResponseOnRemote) && (
                    < button className={`${styles[`serie-${indexSerie + 1}`]}`} onClick={() => handleClickMedia(true)}>
                      {(!responseType || responseType === 'video') && <img src={play} alt="play" />}
                      {responseType === 'audio' && <BsMusicNoteBeamed size="20px" />}
                      {responseType === 'image' && <BsImageFill size="20px" />}
                    </button>
                )}
                {(mediaAnswer.source
                  && fnStopMediaResponseOnRemote
                  && fnLaunchMediaResponseOnRemote) && (
                    <div className={`${styles['controls-media']} ${styles.mediaAnswer}`}>
                      <button className={`${styles[`serie-${indexSerie + 1}`]}`} onClick={() => handleClickMedia(true)}>
                        {(!responseType || responseType === 'video') && <img src={play} alt="play" />}
                        {responseType === 'audio' && <BsMusicNoteBeamed />}
                        {responseType === 'image' && <BsImageFill size="20px" />}
                      </button>
                      {fnStopMediaResponseOnRemote && <button className={`${styles[`serie-${indexSerie + 1}`]}`}><FaStop size={'17px'} onClick={(e) => handleStopMedia(e)} /></button>}
                    </div>
                )}
              </div>
            </>
          }
        </span>

      </div>
    </div >
  );
};

CardPreview.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  rubrique: PropTypes.string.isRequired,
  difficulty: PropTypes.number.isRequired,
  points: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  indexSerie: PropTypes.number.isRequired,
  idSerie: PropTypes.number,
  optsFeeback: PropTypes.array,
  fnLaunchMediaOnRemote: PropTypes.func,
  content: PropTypes.object,
  isAnswer: PropTypes.bool,
  canReload: PropTypes.bool,
  media: PropTypes.object,
  mediaAnswer: PropTypes.object,
};

export default CardPreview;
