import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IoChevronUp, IoChevronDown } from 'react-icons/io5';
// import { HiCheck } from 'react-icons/hi';
import { putTeamsScore } from '../../../actions/quiz';
import { prepareScoreForPost, getDate } from '../../../helpers';
import FormAddTeam from '../../../components/FormAddTeam';
import Conseil from '../../../components/Conseil';
import LoaderSpinner from '../../../components/LoaderSpinner';
import { ListTeams } from '../../../components/Teams';
import styles from './result-teams.module.scss';

const ResultTeams = () => {
  const dispatch = useDispatch();
  const quizReducer = useSelector((state) => state.quizReducer);
  const { teams, session } = quizReducer;
  const [sort, setSort] = useState('asc');
  const history = useHistory();
  const teamsRef = useRef();
  const containerRef = useRef();

  function handleClickAnchor() {
    const { current: element } = containerRef;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  function getTeams() {
    const arr = teams.map((team) => {
      const clone = Object.assign(team, {});
      // if (!search || (clone.nom && clone.nom.toLowerCase().indexOf(search.toLowerCase()) >= 0)) {
      //   clone.hide = false;
      // } else {
      //   clone.hide = true;
      // }
      clone.hide = false;
      return clone;
    });

    return arr;
  }

  function handleValidateResult() {
    if (quizReducer.loadingType !== 'set_score') {
      const resTeams = teamsRef.current.getTeams();
      const scores = prepareScoreForPost(resTeams);
      putTeamsScore(dispatch, scores).then(() => {
        history.push('/app/result/ranking');
      });
    }
  }

  return (
    <div className={styles['result-teams']} ref={containerRef}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <h2>Cotton quiz</h2>
            <p >
              <span className={styles.date}>{getDate(session.date)}</span><span className='bold'> {session.heure_debut}</span> <br />
              {session.client.nom}, {session.client.ville}
            </p>
          </div>
          <Conseil containerClassName={styles.advices} zoningCode='resultats_equipes_J1' />
          <div className={styles['container-button']}>
            {teams && (
              <button onClick={() => setSort((state) => (state === 'asc' ? 'desc' : 'asc'))} className={`${styles.btnSort} secondary`} >
                <IoChevronUp />
                <IoChevronDown />
              </button>
            )}
            <button onClick={() => handleValidateResult()} className="secondary" >
              {quizReducer.loadingType === 'set_score'
                ? <LoaderSpinner white size={'sm'} />
                : 'photos'
              }
            </button>
          </div>
          <p onClick={() => handleClickAnchor()} className={styles.anchor}>Ajouter une équipe</p>
        </div>
        <ListTeams
          ref={teamsRef}
          hideAlphabet
          sort={sort}
          teams={getTeams()}
          hideScore={false}
          dropDownSerie
        />
        <div className={styles.containerForm}>
          <FormAddTeam withScore={true} withBonus={false} />
        </div>
      </div>
    </div>
  );
};

export default ResultTeams;
