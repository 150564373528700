import React from 'react';
// import { BsMusicNoteBeamed } from 'react-icons/bs';
import { MediaYoutube, MediaAudio } from '../Media';
// import { getVideoId } from '../../helpers';
import ModalStandalone from '../../lib/ModalStandalone';
import styles from './modal-media.module.scss';

const ModalMedia = ({
  modalRef,
  source,
  autoPlay = false,
  questionType = null,
  type = 'video',
}) => {
  function close() {
    if (modalRef.current) modalRef.current.close();
  }

  return (
    <ModalStandalone ref={modalRef}>
      <div className={styles.modal}>
        {type === 'image' && (
          <div className={styles['container-image']}>
            <img src={source} alt="preview" />
          </div>
        )}
        {type === 'video' && questionType === 'audio' && (
          <div className={styles.videoAudio}>
            <MediaYoutube
              autoPlay={autoPlay}
              source={source}
            />
          </div>
        )}
        {type === 'video' && questionType === 'video' && (
          <MediaYoutube
            autoPlay={autoPlay}
            source={source}
          />
        )}
        {type === 'audio' && (
          <MediaAudio
            controls
            autoPlay={autoPlay}
            source={source}
          />
        )}
        <button onClick={() => close()}>FERMER</button>
      </div>
    </ModalStandalone>
  );
};

export default ModalMedia;
