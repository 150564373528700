import React, { useState, useEffect } from 'react';
import {
  useHistory,
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';
import { IoChevronForward } from 'react-icons/io5';
import { BiArrowBack } from 'react-icons/bi';
import { API_URL } from '../../constant';
import Installation from './installation';
import Rules from './rules';
import FAQ from './faq';
import Contact from './contact';
import styles from './help.module.scss';

const Help = () => {
  const history = useHistory();
  const [contentContact, setContentContact] = useState();
  const [contentInstallation, setContentInstallation] = useState();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    async function getContentContact() {
      if (token) {
        let res;
        const url = `${API_URL}/quiz/contact`;
        const promise = await fetch(url, config);
        if (promise.ok) res = await promise.json();
        if (res?.contacts) setContentContact(res.contacts);
      }
    }

    async function getContentInstallation() {
      if (token) {
        let res;
        const url = `${API_URL}/quiz/installation`;
        const promise = await fetch(url, config);
        if (promise.ok) res = await promise.json();
        if (res?.installations) setContentInstallation(res.installations);
      }
    }

    getContentInstallation();
    getContentContact();
  }, []);

  return (
    <div className={styles.help}>
      <div className={styles.container}>
        <Switch>
          <Route exact path='/app/help'>
            <div className={styles['home-help']}>
              <div className={styles['header-desktop']}>
                <div className={styles.title}>
                  <a onClick={() => history.goBack()}><BiArrowBack size={34} /></a>
                  <h1>Aide</h1>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.left}>
                  <ul className={styles.menu}>
                    <li className='only-on-tablet'><NavLink to='/app/help/installation' ><span>{"Aide à l'installation"}</span><IoChevronForward /></NavLink></li>
                    <li><NavLink to='/app/help/rules' ><span>Principe et règles du jeu</span><IoChevronForward /></NavLink></li>
                    <li><NavLink to='/app/help/faq' ><span>FAQ</span><IoChevronForward /></NavLink></li>
                    <li className='only-on-tablet'><NavLink to='/app/help/contact' ><span>Nous contacter</span><IoChevronForward /></NavLink></li>
                  </ul>
                  {contentContact && (
                    <div className={`only-on-desktop ${styles.contact}`}>
                      <div className={styles.container}>
                        {contentContact?.[0]?.nom && (
                          <h4 dangerouslySetInnerHTML={{ __html: contentContact[0].nom }} />
                        )}
                        {contentContact?.[0]?.descriptif_long && (
                          <span
                            dangerouslySetInnerHTML={{ __html: contentContact[0].descriptif_long }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className={`only-on-desktop ${styles.right}`}>
                  {contentInstallation && (
                    <div className={styles.installation}>
                      <h2>{"Aide à l'installation"}</h2>
                      <ul>
                        {contentInstallation.map((d, i) => (
                          <li key={`faq-${i}`}>
                            <h4 dangerouslySetInnerHTML={{ __html: d.nom }} />
                            <span dangerouslySetInnerHTML={{ __html: d.descriptif_long }} />
                            {d.media.length > 0 && (
                              <>
                                {d.media.map((img, j) => (
                                  <img key={`img-${i}-${j}`} src={`${d.mediaBaseUrl}${img.nom}`} />
                                ))}
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Route>
          <Route exact path='/app/help/installation'>
            <Installation />
          </Route>
          <Route exact path='/app/help/rules'>
            <Rules />
          </Route>
          <Route exact path='/app/help/faq'>
            <FAQ />
          </Route>
          <Route exact path='/app/help/contact'>
            <Contact />
          </Route>
        </Switch>
      </div>

    </div>
  );
};

export default Help;
