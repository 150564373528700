import React from 'react';
import {
  // useLocation,
  Switch,
  Route,
} from 'react-router-dom';
// import { animated, useTransition } from 'react-spring';
import HandleAuth from '../components/HandleAuth';
import Home from '../pages/home';
import Teams from '../pages/teams';
import Review from '../pages/review';
import Quiz from '../pages/quiz';
import Result from '../pages/result';
import Configuration from '../pages/configuration';
import Help from '../pages/help';
import Feedback from '../pages/feedback';
import Topbar from '../components/TopBar';
import BottomBar from '../components/BottomBar';
import SidebarDesktop from '../components/SidebarDesktop';

const ProtectedRoutes = () => (
  <HandleAuth>
    <Topbar />
    <SidebarDesktop />
    <Switch>
      <Route exact path="/app/home">
        <Home />
      </Route>
      <Route exact path="/app/teams">
        <Teams />
      </Route>
      <Route exact path="/app/review">
        <Review />
      </Route>
      <Route exact path="/app/result/:subpage">
        <Result />
      </Route>
      <Route exact path="/app/quiz/:serie">
        <Quiz />
      </Route>
      <Route exact path="/app/feedback">
        <Feedback />
      </Route>
      <Route exact path="/app/configuration">
        <Configuration />
      </Route>
      <Route exact path="/app/help/:page?">
        <Help />
      </Route>
    </Switch>
    <BottomBar />
  </HandleAuth>
);

// const location = useLocation();
// const transitions = useTransition(location, {
//   keys: null,
//   from: { opacity: 0, transform: 'translate3d(0,50%,0)' },
//   enter: { opacity: 1, transform: 'translate3d(0,0%,0)' },
//   leave: { opacity: 0, transform: 'translate3d(0,50%,0)' },
// });

// {transitions((props, item) => (
//   <animated.div style={props}>
//     <Switch location={item}>
//       <Route exact path="/app/home">
//         <Home/>
//       </Route>
//       <Route exact path="/app/help">
//       <Help/>
//       </Route>
//     </Switch>
//   </animated.div>
// ))}

export default ProtectedRoutes;
