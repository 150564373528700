import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { IoAddOutline } from 'react-icons/io5';
import ModalBonus from '../../ModalBonus';
import BlueStar from '../../../assets/images/icons/blue-star.svg';
import GreenStart from '../../../assets/images/icons/green-star.svg';
import editScore from '../../../assets/images/icons/edit-score.svg';
import styles from './item-team.module.scss';

const ItemTeam = ({
  team,
  name = '',
  score = null,
  bonus = null,
  indexSerie = null,
  handleChangeScore = null,
  children = null,
  disabledInput,
  setEnabledInput,
}) => {
  const modalRef = useRef();
  const [open, setOpen] = useState(false);
  const [positionModal, setPositionModal] = useState({ top: '0px', left: '0px' });
  const inputSerieScoreRef = useRef();

  useEffect(() => {
    if (disabledInput) setOpen(false);
  }, [disabledInput]);

  function handleChange(value) {
    if (handleChangeScore) {
      let val = parseInt(value, 10);
      if (Number.isNaN(val)) val = '';
      handleChangeScore(val);
    }
  }

  function handleOpenModal(e) {
    const {
      top, bottom, left, height,
    } = e.target.getBoundingClientRect();
    let center = {
      bottom: `${((window.innerHeight - bottom) - height / 2) - 6}px`,
      left: `${left}px`,
      below: true,
    };

    if (top < 250) {
      center = {
        top: `${(top - height / 2) - 6}px`,
        left: `${left}px`,
        below: false,
      };
    }

    setPositionModal(center);
    modalRef.current.toggle();
  }

  function getClassName() {
    let className = typeof indexSerie === 'number' ? styles['serie-score'] : styles.score;
    // if (typeof indexSerie === 'number') className += ` ${styles[`serie-${indexSerie}`]}`;
    if (typeof handleChangeScore !== 'function' || disabledInput) className += ` ${styles.disabled}`;
    return className;
  }

  function getClassNameItem() {
    let className = styles.team;
    if (handleChangeScore && typeof indexSerie === 'number') {
      className += ` ${styles['editable-serie']}`;
    } else if ((handleChangeScore && !indexSerie) || (children && !indexSerie)) {
      className += ` ${styles['editable-quiz']}`;
    }
    return className;
  }

  function handleRemoveZero() {
    if (score === 0) handleChange(null);
  }

  function handleBlur() {
    if (Number.isNaN(score)) handleChange(0);
  }

  return (
    <div className={getClassNameItem()}>
      <ModalBonus
        modalRef={modalRef}
        positionModal={positionModal}
        team={team}
        indexSerie={indexSerie}
      />
      <div className={styles.container} onClick={
        disabledInput ? () => { } : () => setOpen((state) => !state)
      }>
        <div>
          <p className={styles.name}>{name}</p>
        </div>
        <div className={styles.containerInput}>
          {(typeof score === 'number' && typeof indexSerie !== 'number') && (
            <>
              <div
                className={getClassName()}
                onClick={
                  disabledInput ? () => setEnabledInput() : () => { }
                }
              >
                <input
                  type='number'
                  min='0'
                  disabled={typeof handleChangeScore !== 'function'}
                  value={Number.isNaN(score) || score < 0 ? '' : score}
                  onChange={(e) => handleChange(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveZero();
                  }}
                  onBlur={() => handleBlur()}
                />pts
              </div>
              <div
                onClick={
                  disabledInput ? () => setEnabledInput() : () => { }
                }
                className={disabledInput ? `${styles.btnEdit}` : `${styles.btnEdit} ${styles.hide}`}
              >
                <img alt="" src={editScore} />
              </div>
            </>

          )}
          {(typeof score === 'number' && typeof indexSerie === 'number') && (
            <>
              {bonus === null && <div className={styles.icon} onClick={(e) => handleOpenModal(e)}><img src={BlueStar} alt="répondre à la question bonus" /></div>}
              {bonus === true && <div className={styles.icon}><img src={GreenStart} alt="bonne réponse à la question bonus" /></div>}
              {bonus === false && <div className={styles.icon} style={{ transform: 'rotate(-45deg)' }}><IoAddOutline color="#F4313F" size={'30px'} /></div>}
              <div
                className={getClassName()}
                onClick={
                  disabledInput ? () => setEnabledInput() : () => { }
                }
              >
                <input
                  ref={inputSerieScoreRef}
                  type='number'
                  min='0'
                  disabled={typeof handleChangeScore !== 'function'}
                  value={Number.isNaN(score) || score < 0 ? '' : score}
                  onChange={(e) => handleChange(e.target.value)}
                  onClick={() => handleRemoveZero()}
                  onBlur={() => handleBlur()}
                /> pts
              </div>
              <div
                onClick={
                  disabledInput ? () => setEnabledInput() : () => { }
                }
                className={disabledInput ? `${styles.btnEdit}` : `${styles.btnEdit} ${styles.hide}`}
              >
                <img alt="" src={editScore} />
              </div>
            </>
          )}
        </div>
      </div>
      {(children && open) && children}
    </div>
  );
};

export default ItemTeam;
