//= =====================
// Quizz Actions
//= =====================

export const GET_QUIZ_SERIES = 'GET_QUIZ_SERIES';
export const RELOAD_QUIZ_SERIES = 'RELOAD_QUIZ_SERIES';
export const PUT_SCORES = 'PUT_SCORES';
export const UPADTE_TEAM = 'UPADTE_TEAM';
export const UPADTE_TEAM_2 = 'UPADTE_TEAM_2';
export const PUT_SESSION = 'PUT_SESSION';
export const PUT_CLIENT_HELP = 'PUT_CLIENT_HELP';
export const PUT_FEEDBACK_QUESTION = 'PUT_FEEDBACK_QUESTION';
export const ADD_TEAM = 'ADD_TEAM';
export const SET_LOADING_TYPE = 'SET_LOADING_TYPE';
export const CREATE_TEAM_LOADING = 'CREATE_TEAM_LOADING';
export const QUIZ_LOADING = 'QUIZ_LOADING';
export const GET_QUIZ_TEAMS = 'GET_QUIZ_TEAMS';
export const ERROR_QUIZ = 'ERROR_QUIZ';
