import React, { useState, useEffect, useRef } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useForm, useController } from 'react-hook-form';
import { IoAddOutline } from 'react-icons/io5';
import { createTeam, editTeam } from '../../actions/quiz';
import usePrevious from '../../hooks/usePrevious';
import ModalBonus from '../ModalBonus';
import InputTeamName from '../InputTeamName';
import { InputEmail } from '../../lib/HooksFormFields';
import BlueStar from '../../assets/images/icons/blue-star.svg';
import GreenStart from '../../assets/images/icons/green-star.svg';
import styles from './formAddTeam.module.scss';

const FormAddTeam = ({
  indexSerie,
  withScore = true,
  withBonus = true,
  noShadow = false,
}) => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [keyInputName, setKeyInputName] = useState(Date.now());
  const [teamAlreadyJoin, setTeamAlreadyJoin] = useState(Date.now());

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const quizReducer = useSelector((state) => state.quizReducer);
  const { session, teams } = quizReducer;
  const [bonus, setBonus] = useState(null);
  const [positionModal, setPositionModal] = useState({ top: '0px', left: '0px' });
  const watchEmail = watch('email');
  const watchTeamName = watch('nom');
  const prevTeamName = usePrevious(watchTeamName);

  const controlScore = useController({
    control,
    name: 'score',
    defaultValue: '',
    rules: {
      min: 0,
    },
  });

  const controlName = useController({
    control,
    name: 'nom',
    rules: {
      required: 'Veuillez indiquer le nom de l\'équipe',
    },
  });

  // SET EMAIL VALUE ACCORDING TO SELECTED TEAM
  // REMOVE IT IF UNSELECT AN EXISTING TEAM
  useEffect(() => {
    if (prevTeamName && watchEmail === prevTeamName.email) {
      setValue('email', '');
    }
    if (!watchTeamName?.email) {
      return;
    }
    setValue('email', watchTeamName?.email);
  }, [watchTeamName?.email]);

  // WATCH IF A TEAM EXIST ON THIS QUIZ WITH THE CURRENT NAME
  useEffect(() => {
    const find = teams.find((team) => team.nom === watchTeamName?.value);
    setTeamAlreadyJoin(!!find);
  }, [watchTeamName?.value, teams]);

  function onSubmit(data) {
    const obj = { ...data };
    const { id: idSession } = quizReducer.session;
    const isNewTeam = !!obj.nom.isNew;
    const teamId = isNewTeam ? null : obj.nom.value;

    const points = {
      equipe_quiz_bonus_points: typeof bonus === 'number' ? bonus : -1,
      equipe_quiz_points: -1,
      equipe_quiz_serie_1_points: -1,
      equipe_quiz_serie_2_points: -1,
      equipe_quiz_serie_3_points: -1,
      equipe_quiz_serie_4_points: -1,
    };

    if (withScore) {
      if (typeof indexSerie === 'number') points[`equipe_quiz_serie_${indexSerie + 1}_points`] = parseInt(obj.score || -1, 10);
      points.equipe_quiz_points = parseInt(obj.score || -1, 10);
    }

    if (typeof bonus === 'number' && bonus > 0) {
      points.equipe_quiz_points += bonus;
    }

    delete obj.score;
    obj.nom = obj.nom.label;
    if (!isNewTeam) {
      editTeam(dispatch, {
        ...obj,
        id_session: idSession,
        points,
      }, teamId).then(() => {
        setValue('nom', null);
        setValue('score', '');
        setValue('email', '');
        setKeyInputName(Date.now());
      });
    } else {
      createTeam(dispatch, {
        ...obj,
        id_session: idSession,
        points,
      }).then(() => {
        setValue('nom', null);
        setValue('score', '');
        setValue('email', '');
        setKeyInputName(Date.now());
      });
    }
  }

  function handleOpenModal(e) {
    const {
      top, bottom, left, height,
    } = e.target.getBoundingClientRect();
    let center = {
      bottom: `${((window.innerHeight - bottom) - height / 2) + 4}px`,
      left: `${left}px`,
      below: true,
    };

    if (top < 250) {
      center = {
        top: `${(top - height / 2) - 6}px`,
        left: `${left}px`,
        below: false,
      };
    }

    setPositionModal(center);
    modalRef.current.toggle();
  }

  return (
    <>
      <ModalBonus
        modalRef={modalRef}
        positionModal={positionModal}
        indexSerie={indexSerie}
        setBonus={setBonus}
      />
      <div className={styles.container}>
        <div className={noShadow ? `${styles.form} ${styles.noShadow}` : styles.form}>
          <div className={styles.team}>
            <InputTeamName keyInput={keyInputName} control={controlName} sessionId={session.id} />
            {withBonus
              && <>
                {bonus === null && <div className={styles.icon} onClick={(e) => handleOpenModal(e)}><img src={BlueStar} alt="répondre à la question bonus" /></div>}
                {typeof bonus === 'number' && bonus > 0 && <div className={styles.icon} onClick={(e) => handleOpenModal(e)}><img src={GreenStart} alt="bonne réponse à la question bonus" /></div>}
                {typeof bonus === 'number' && bonus === 0 && <div className={styles.icon} onClick={(e) => handleOpenModal(e)} style={{ transform: 'rotate(-45deg)' }}><IoAddOutline color="#F4313F" size={'30px'} /></div>}
              </>
            }
            {withScore && <div className={typeof indexSerie === 'number' ? `${styles.score} ${styles[`serie-${indexSerie + 1}`]}` : `${styles.score} ${styles.total}`}>
              <input type='number' {...controlScore.field} />pts
            </div>}
          </div>
          <div className={styles.email}>
            <InputEmail
              name='email'
              control={control}
              required
              placeholder='Email (obligatoire)'
            />
          </div>
          <div className='center'>
            <button className={
              (errors?.email?.message || errors?.nom?.message)
                || (!watchTeamName || !watchEmail)
                || teamAlreadyJoin
                ? 'secondary disabled'
                : 'secondary'} onClick={handleSubmit(onSubmit)}>Ajouter une équipe</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAddTeam;
