import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoAddOutline } from 'react-icons/io5';
import {
  putTeamsBonus,
} from '../../actions/quiz';
import { prepareScoreForPost } from '../../helpers';
import ModalStandalone from '../../lib/ModalStandalone';
import LoaderSpinner from '../LoaderSpinner';
import GreenStart from '../../assets/images/icons/green-star.svg';
import closemodal from '../../assets/images/icons/close-modal.svg';

import styles from './modal-bonus.module.scss';

const ModalBonus = ({
  modalRef,
  positionModal,
  team,
  indexSerie,
  setBonus,
}) => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const dispatch = useDispatch();

  function saveScoreBonus(isCorrect) {
    let bonus = 0;
    if (isCorrect) {
      switch (indexSerie) {
        case 0:
          bonus = 50;
          break;
        case 1:
          bonus = 30;
          break;
        case 2:
          bonus = 20;
          break;
        case 3:
          bonus = 10;
          break;
        default:
          bonus = 0;
          break;
      }
    }

    if (team) {
      const res = prepareScoreForPost([team]);
      res[0].points.equipe_quiz_bonus_points = bonus;
      res[0].points.equipe_quiz_points += bonus;
      putTeamsBonus(dispatch, res).then(() => {
        modalRef.current.close();
      });
    } else {
      setBonus(bonus);
      modalRef.current.close();
    }
  }

  return (
    <ModalStandalone ref={modalRef}>
      <ul
        className={styles['modal-bonus']}
        style={positionModal}
      >
        {!positionModal.below && (
          <li className={styles.close}>
            {quizReducer.loadingType === 'set_bonus'
              ? <div className={styles.loader}><LoaderSpinner size={'sm'} white /></div>
              : <img src={closemodal} alt="Fermer" onClick={() => modalRef.current.close()} />
            }
          </li>
        )}
        <li onClick={() => saveScoreBonus(true)}>
          <div className={styles.icon}><img src={GreenStart} alt="bonne réponse à la question bonus" /></div>
          Bonne réponse
        </li>
        <li onClick={() => saveScoreBonus(false)}>
          <div className={styles.icon} style={{ transform: 'rotate(-45deg)' }}>
            <IoAddOutline color="#F4313F" size={'25px'} /></div>
          Mauvaise réponse
        </li>
        {positionModal.below && (
          <li className={styles.close}>
            {quizReducer.loadingType === 'set_bonus'
              ? <div className={styles.loader}><LoaderSpinner size={'sm'} white /></div>
              : <img src={closemodal} alt="Fermer" onClick={() => modalRef.current.close()} />
            }
          </li>
        )}
      </ul>
    </ModalStandalone>
  );
};

export default ModalBonus;
