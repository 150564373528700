import React, {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';
import { useSpring, animated, config } from 'react-spring';

const AnimatedButton = ({ children, intensity = 'normal' }, ref) => {
  const [animationOn, setAnimationOn] = useState(true);

  const { x } = useSpring({
    x: animationOn ? 0 : 1,
    config: { ...config.wobbly },
  });

  useImperativeHandle(ref, () => ({
    trigger: () => setAnimationOn(!animationOn),
  }));

  function getOutput() {
    let output;
    switch (intensity) {
      case 'normal':
        output = [1, 0.8, 1];
        break;
      case 'gentle':
        output = [1, 0.92, 1];
        break;
      case 'hard':
        output = [1, 0.7, 1];
        break;
      default:
        output = [1, 0.8, 1];
        break;
    }
    return output;
  }

  return (
    <animated.div
      onClick={() => setAnimationOn((state) => !state)}
      style={{
        transform: x.to({
          range: [0, 0.5, 1],
          output: getOutput(),
        }).to((val) => `scale(${val})`),
      }}>
      {children}
    </animated.div>

  );
};

export default forwardRef(AnimatedButton);
