import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './conseil.module.scss';

const Conseil = ({ className, containerClassName = '', zoningCode }) => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const [advice, setAdvice] = useState();

  useEffect(() => {
    if (!quizReducer.advices) return;
    const found = quizReducer.advices.find((d) => d.zoning_code === zoningCode);
    if (found) setAdvice(found.descriptif_long);
  }, [zoningCode, quizReducer.advices]);

  function getClassName() {
    let res = styles.conseil;
    if (className) res += ` ${className}`;
    else res += ` ${styles.primary}`;
    return res;
  }

  return (
    <>
      {advice && quizReducer.showAdvices && (
        <div className={containerClassName}>
          <div className={getClassName()} dangerouslySetInnerHTML={{ __html: advice }} />
        </div>
      )}
    </>

  );
};

Conseil.propTypes = {
  zoningCode: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Conseil;
