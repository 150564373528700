const style = {
  container: (provided) => ({ ...provided }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    border: '1px solid rgba(47, 48, 78, 0.06)',
    backgroundColor: 'rgba(130, 170, 228, 0.1)',
    borderRadius: '14px',
    height: '42px',
    '&:hover': {
      border: '1px solid rgba(47, 48, 78, 0.06)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Montserrat-Regular',
    fontSize: '14px',
    color: 'rgba(47, 48, 78, 0.5)',
  }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Montserrat-Bold',
    fontSize: '14px',
    color: 'rgba(47, 48, 78, 0.5)',
  }),
  multiValue: (provided) => ({ ...provided, fontSize: '14px' }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid #00A8E8',
    backgroundColor: '#F2F6FC',
    borderRadius: '14px',
    boxShadow: '',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '144px',
  }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    fontFamily: 'Montserrat-Bold',
    fontSize: '14px',
    color: 'rgba(47, 48, 78, 0.5)',
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: state.isSelected ? 'Montserrat-Bold' : 'Montserrat-Regular',
    fontSize: '14px',
    color: state.isSelected ? '#00A8E8' : 'rgba(47, 48, 78, 0.5)',
    cursor: 'pointer',
    backgroundColor: '',
    '&:hover': {
      fontFamily: 'Montserrat-Bold',
      color: '#00A8E8',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided }),
};

export default style;
