import React, { useState, useEffect, useRef } from 'react';
import Compressor from 'compressorjs';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { isDeviceMobile, isTooLate } from '../../helpers';
import { putSession, postSessionImage, sendWsEvent } from '../../actions/quiz';
import ModalNextEvent from '../../components/ModalNextEvent';
import {
  InputText,
  TextEditor,
  InputFile,
  Textarea,
} from '../../lib/HooksFormFields';
import LoaderSpinner from '../../components/LoaderSpinner';
import Conseil from '../../components/Conseil';
import pencil from '../../assets/images/icons/circle-pencil.svg';
import styles from './configuration.module.scss';

const Configuration = () => {
  const dispatch = useDispatch();
  const quizReducer = useSelector((state) => state.quizReducer);
  const [isLoading, setIsLoading] = useState([]);
  const modalRef = useRef();
  const history = useHistory();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
  } = useForm();

  const {
    control: controlLots,
    watch: watchLots,
    handleSubmit: handleSubmitLots,
  } = useForm();

  const {
    control: controlFile,
    watch: watchFile,
    handleSubmit: handleSubmitFile,
  } = useForm();

  // WATCH
  const watchMessage = watch('diffusion_message');
  const watchLot1 = watchLots('lot_1');
  const watchLot2 = watchLots('lot_2');
  const watchLot3 = watchLots('lot_3');
  const watchFieldFile = watchFile('image');

  function fnCompressFile(file) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.80,
        maxWidth: 1200,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  function onSubmit(data, section) {
    if (Object.values(data).find((d) => d)) {
      putSession(dispatch, data).then(() => {
        setIsLoading((state) => state.filter((d) => d !== section));
        sendWsEvent({
          action: 'fetch_session',
        });
      });
    }
  }

  async function onSubmitFile(data) {
    const { client, id } = quizReducer.session;
    const formData = new FormData();
    const compressFile = await fnCompressFile(data.image);
    // formData.append('file', data.image);
    formData.append('file', compressFile);
    formData.append('id_session', id);
    formData.append('id_client', client.id);
    setIsImageLoading(true);
    postSessionImage(dispatch, formData).then(() => {
      setIsImageLoading(false);
      sendWsEvent({
        action: 'fetch_session',
      });
    });
  }

  function getSessionImage() {
    let source;
    const { session } = quizReducer;
    const { media, mediaBaseUrl } = session;
    let img;
    if (media.length > 0) {
      img = media[media.length - 1];
      source = `${mediaBaseUrl}${img?.nom}`;
    }
    return source;
  }

  // HANDLE SUBMIT FILE
  useEffect(() => {
    if (watchFieldFile) handleSubmitFile(onSubmitFile)();
  }, [watchFieldFile]);

  function submitLots() {
    if (watchLot1 || watchLot2 || watchLot3) {
      setIsLoading((state) => [...state, 'lots']);
      handleSubmitLots((data) => onSubmit(data, 'lots'))();
    }
  }

  function submitMessage() {
    if (watchMessage) {
      setIsLoading((state) => [...state, 'message']);
      handleSubmit((data) => {
        const updatedData = { ...data };
        if (isDeviceMobile()) {
          updatedData.diffusion_message = `<p>${updatedData.diffusion_message}</p>`;
        }
        return onSubmit(updatedData, 'message');
      })();
    }
  }

  function getNextEvent() {
    const { session } = quizReducer;
    let event = {};
    if (
      !session.diffusion_evenement_date
      && !session.diffusion_evenement_heure
      && !session.diffusion_evenement_nom
    ) {
      event = null;
    } else {
      event = {
        diffusion_evenement_date: session.diffusion_evenement_date,
        diffusion_evenement_heure: session.diffusion_evenement_heure,
        diffusion_evenement_nom: session.diffusion_evenement_nom,
      };
    }
    return event;
  }

  function clearHtmlTags(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  }

  return (
    <div className={styles.configuration}>
      {isTooLate(quizReducer?.session) && <Redirect to='/app/result/teams' />}
      <ModalNextEvent modalRef={modalRef} defaultNextEvent={getNextEvent()} />
      <div className={styles.container}>
        <div className={styles['header-desktop']}>
          <a onClick={() => history.goBack()}><BiArrowBack size={34} /></a>
          <h1>Personnalisation</h1>
        </div>
        <Conseil containerClassName={styles.advices} zoningCode='personnalisation' />
        <div className={styles.form}>
          <div className={styles['form-col']}>
            <section>
              <div className={styles['title-section']}>
                <h3>Lots à gagner ce soir</h3>
              </div>
              <div className={styles['container-prizes']}>
                <InputText
                  name="lot_1"
                  control={controlLots}
                  defaultValue={quizReducer.session.lot_1}
                  placeholder="1er lot"
                />
                <InputText
                  name="lot_2"
                  control={controlLots}
                  defaultValue={quizReducer.session.lot_2}
                  placeholder="2ème lot"
                />
                <InputText
                  name="lot_3"
                  control={controlLots}
                  defaultValue={quizReducer.session.lot_3}
                  placeholder="3ème lot"
                />
              </div>
              <div className='center'>
                <button className='secondary' onClick={(e) => submitLots(e)}>
                  Valider
                  {(Array.isArray(isLoading) && isLoading.includes('lots')) && <LoaderSpinner white size='sm' />}
                </button>
              </div>
            </section>
            <section>
              <div className={styles['title-section']}>
                <h3>Message</h3>
              </div>
              <div className={styles['container-message']}>
                {!isDeviceMobile()
                  ? <TextEditor
                    name="diffusion_message"
                    defaultValue={quizReducer.session.diffusion_message}
                    control={control}
                    maxLength={200}
                  />
                  : <Textarea
                    name="diffusion_message"
                    defaultValue={clearHtmlTags(quizReducer.session.diffusion_message)}
                    control={control}
                    maxLength={200}
                  />
                }
              </div>
              <div className='center'>
                <button className='secondary' onClick={(e) => submitMessage(e)}>
                  Valider
                  {(Array.isArray(isLoading) && isLoading.includes('message')) && <LoaderSpinner white size='sm' />}
                </button>
              </div>
            </section>
          </div>
          <div className={styles['form-col']}>
            <section>
              <div className={styles['title-section']}>
                <h3>Image</h3>
              </div>
              <div className={styles['container-picture']}>
                {isImageLoading && <span className={styles.loader}><LoaderSpinner white /></span>}
                <InputFile
                  name="image"
                  defaultValue={getSessionImage()}
                  control={controlFile}
                />
              </div>
            </section>
            <section>
              <div className={styles['title-section']}>
                <h3>Prochain événement</h3>
              </div>
              <div className={styles.event}>
                {getNextEvent()
                  ? <div className={styles['next-event']}>
                    <img className={styles.edit} src={pencil} alt="éditer le prochaine événement" onClick={() => modalRef.current.toggle()} />
                    <p className={styles.date}>
                      <span>{getNextEvent().diffusion_evenement_date}</span>
                      <span className={styles.separator}> | </span>
                      <span>{getNextEvent().diffusion_evenement_heure}</span>
                    </p>
                    <p>{getNextEvent().diffusion_evenement_nom}</p>
                  </div>
                  : <div className={styles['add-event']}>
                    <p>{'Votre événement sera annoncé à la fin du quiz'}</p>
                    <div className='center'>
                      <button type="button" className='secondary' onClick={() => modalRef.current.toggle()}>Ajouter</button>
                    </div>
                  </div>
                }
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
