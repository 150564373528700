import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import pencil from '../../../assets/images/icons/circle-pencil.svg';
import styles from './input-file.module.scss';

const InputFile = ({
  name,
  control,
  defaultValue,
}) => {
  const containerRef = useRef();
  const [preview, setPreview] = useState();

  useEffect(() => {
    setPreview(defaultValue);
  }, [defaultValue]);

  function browserFile(e) {
    const input = containerRef.current.querySelector('input');
    input.click();
    e.stopPropagation();
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{}}
      render={({
        field: {
          // name,
          ref,
          onChange,
        },
      }) => (
        <div onClick={(e) => browserFile(e)} ref={containerRef}
         className={preview ? `${styles['input-file']} ${styles['width-picture']}` : `${styles['input-file']}`}
        >
          <input
            style={{ display: 'none' }}
            ref={ref}
            type='file'
            accept="image/png, image/jpeg"
            onChange={(e) => {
              const objectUrl = URL.createObjectURL(e.target.files[0]);
              setPreview(objectUrl);
              onChange(e.target.files[0]);
            }}
            className={styles.input}
          />
          {preview && <img src={preview} />}
          <div className={styles.instructions}>
            <div className={styles.icon}>
              <Image />
            </div>
            <span>
              <p>Ajouter une image</p>
              <p className={styles.sub}>Format jpg, png</p>
            </span>
          </div>
          <div onClick={(e) => browserFile(e)} className={styles.button}>
            <img src={pencil} alt="changer d'image" />
          </div>
        </div>
      )}
    />

  );
};

const Image = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="ai ai-Image">
    <path d="M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6z" />
    <circle cx="8.5" cy="8.5" r="2.5" />
    <path d="M14.526 12.621L6 22h12.133A3.867 3.867 0 0 0 22 18.133V18c0-.466-.175-.645-.49-.99l-4.03-4.395a2 2 0 0 0-2.954.006z" />
  </svg>
);

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default InputFile;
