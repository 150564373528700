import React from 'react';
import { BsTypeBold, BsTypeUnderline, BsTypeItalic } from 'react-icons/bs';
import styles from './styled-button.module.scss';

const StyledButton = ({
  label, active, onToggle, onChangeDone, style,
}) => {
  function _onToggle(e) {
    e.preventDefault();
    onToggle(style);
  }

  function _onChangeDone(e) {
    e.preventDefault();
    onChangeDone();
  }

  function getClassName() {
    let className = `${styles.button} RichEditor-styleButton`;
    if (active) {
      className += ` ${styles.active}`;
    }
    return className;
  }

  function getIcons() {
    let icon;
    switch (label) {
      case 'Bold':
        icon = <BsTypeBold/>;
        break;
      case 'Italic':
        icon = <BsTypeItalic/>;
        break;
      case 'Underline':
        icon = <BsTypeUnderline/>;
        break;
      default:
        break;
    }
    return icon;
  }

  // this.props.label
  return (
    <span
      className={getClassName()}
      onMouseDown={(e) => _onToggle(e)}
      onMouseUp={(e) => _onChangeDone(e)}
    >
      {getIcons()}
    </span>
  );
};

export default StyledButton;
