import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { getDate } from '../../helpers';
import gridTeams from '../../assets/documents/CQ_feuille_jeu_mars_2022.pdf';
import styles from './sidebar.module.scss';
import grid from '../../assets/images/icons/grid.svg';
import logout from '../../assets/images/icons/logout.svg';
import help from '../../assets/images/icons/help.svg';

const SidebarDesktop = () => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const { session } = quizReducer;
  const domNode = document.getElementById('sidebar');
  const location = useLocation();

  function getContent() {
    let elts = null;
    if (!location.pathname.startsWith('/app/quiz')) {
      elts = (
        <div className={styles.sidebar}>
          <div className={styles.header}>
            <a href="https://pro.cotton-quiz.com/extranet/games">
              <img src={logout} alt='Imprimer les grilles de jeu' />
              <p>Quitter l’application</p>
            </a>
            <NavLink to="/app/help">
              <p>Accéder à l’espace aide</p>
              <img src={help} alt="aide" />
            </NavLink>
          </div>
          <div className={styles.content}>
            <div>
              <h2>Cotton quiz</h2>
              <p >
                <span className={styles.date}>{getDate(session.date)}</span><span className='bold'> {session.heure_debut}</span> <br />
                {session.client.nom}, {session.client.ville}
              </p>
            </div>
            <div>
              <p className={styles.tag}>
                {!!session.flag_session_privee && <>Privée<br /></>}
                {session.nom_evenement && <>{session.nom_evenement}<br /></>}
                {session.saison.nom}
              </p>
            </div>
          </div>
          <a href={gridTeams} download="grilles_de_jeu.pdf">
            <button className='secondary'>
              <img src={grid} alt='Imprimer les grilles de jeu' />
              <span>Imprimer les grilles de jeu</span>
            </button>
          </a>
        </div>
      );
    }
    return elts;
  }

  return createPortal(
    <>
      {getContent()}
    </>,
    domNode,
  );
};

export default SidebarDesktop;
