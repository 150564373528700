import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BiArrowBack } from 'react-icons/bi';
import { AiFillHome } from 'react-icons/ai';
import { putTeamsScore } from '../../../actions/quiz';
import LoaderSpinner from '../../LoaderSpinner';
import { prepareScoreForPost } from '../../../helpers';
import Conseil from '../../Conseil';
import FormAddTeam from '../../FormAddTeam';
import { ListTeams } from '../../Teams';

import styles from './scores.module.scss';

const Scores = ({
  indexSerie,
  lastSerie,
  listTeamsRef,
  fnNextQuestion,
}) => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const containerRef = useRef();
  // const [search, setSearch] = useState('');

  function handleSubmit(url) {
    const { teams } = quizReducer;
    const scores = prepareScoreForPost(teams);
    putTeamsScore(dispatch, scores).then(() => {
      const obj = { pathname: url };
      fnNextQuestion();
      if (url) history.push(obj);
    });
  }

  function handleClickAnchor() {
    const { current: element } = containerRef;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  return (
    <div className={styles.scores} ref={containerRef}>
      <div className={styles.container}>
        <Conseil containerClassName={styles.advices} zoningCode={lastSerie ? 'animation_scores_derniere' : 'animation_scores'} />
      </div>
      {/*
        <div className={styles.container}>
        <input
          type={'text'}
          placeholder={'Rechercher'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      */}
      <p onClick={() => handleClickAnchor()} className={styles.anchor}>Ajouter une équipe</p>
      <ListTeams
        ref={listTeamsRef}
        teams={quizReducer.teams}
        hideScore={false}
        hideAlphabet
        editableScores
        indexSerie={indexSerie}
        noScroll
      />
      <div className={`${styles.formAddTeam}`}>
        <FormAddTeam indexSerie={indexSerie} />
      </div>
      <div className={styles.container}>
        {lastSerie ? (
          <a onClick={() => handleSubmit()} style={{ color: 'white' }} className={styles['link-next']}>
            <div>
              <span>{'Fin de l\'animation'}</span>
              <span>Aller aux résultats</span>
            </div>
            <span className={styles.arrow}><BiArrowBack size={34} /></span>
          </a>) : (
          <a onClick={() => handleSubmit(`/app/quiz/${indexSerie + 1}`)} style={{ color: 'white' }} className={styles['link-next']}>
            <div>
              <span>Série suivante</span>
              <span>Prochaine série</span>
            </div>
            {quizReducer.loadingType === 'set_score'
              ? <LoaderSpinner white size={'sm'} />
              : <span className={styles.arrow}><BiArrowBack size={34} /></span>
            }
          </a>
        )}
        <div className={`only-on-tablet ${styles['link-home']}`}>
          <NavLink to='/app/home'>
            <AiFillHome size={24} />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

Scores.propTypes = {
  indexSerie: PropTypes.number.isRequired,
};

export default Scores;
