import React, { useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { API_URL } from '../../constant';
import styleJs from './selectStyle';
import styles from './inputteam-name.module.scss';

const promiseOptions = (inputValue, sessionId) => new Promise((resolve) => {
  const token = localStorage.getItem('token');
  const getOptions = async () => {
    const response = await fetch(`${API_URL}/quiz/team/search`, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ nom: inputValue, id_session: sessionId }),
    });
    const content = await response.json();
    if (content.teams) {
      resolve(content.teams);
    } else {
      resolve([]);
    }
  };
  getOptions();
});
// <span className={styles.label}>Créer l&apos;équipe : </span>
const formatOptionLabel = (option, { context }) => (
  <>
    {context === 'menu' && option.__isNew__ ? (
      <span className={styles.createoption}>
        <span className={styles.newValue}>{option.value}</span>
      </span>
    ) : (
      option.label
    )
    }
  </>
);

// cacheOptions

const InputTeamName = ({ control, sessionId, keyInput }) => {
  const inputRef = useRef();
  const valueRef = useRef();
  return (
    <div className={styles.container}>
      <AsyncSelect
        key={`input-key-${keyInput}`}
        ref={inputRef}
        placeholder='Nom équipe'
        defaultOptions
        externalValue={control.field.value}
        loadOptions={(value) => promiseOptions(value, sessionId)}
        noOptionsMessage={() => null}
        styles={styleJs}
        loadingMessage={() => null}
        isClearable
        isSearchable
        formatOptionLabel={formatOptionLabel}
        onInputChange={(value, meta) => {
          if (value && meta.action === 'input-change') {
            const obj = {
              label: value,
              value,
              isNew: true,
            };
            control.field.onChange(obj);
            valueRef.current = obj;
          }
        }}
        onChange={(value, meta) => {
          if (meta.action === 'select-option') {
            const obj = {
              label: value?.label || null,
              value: value?.value || null,
              email: value?.email || null,
              isNew: false,
            };
            control.field.onChange(obj);
            valueRef.current = obj;
          } else if (meta.action === 'clear') {
            valueRef.current = null;
            control.field.onChange(null);
          }
        }}
        onBlur={() => {
          if (valueRef.current) {
            const found = inputRef.current.getCategorizedOptions()
              .find((d) => d.label === valueRef.current.label)?.data;
            control.field.onChange(found ? { ...found, isNew: false } : { ...valueRef.current });
            inputRef.current.setValue(found ? { ...found, isNew: false } : { ...valueRef.current });
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export default InputTeamName;
