import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IoChevronUp, IoChevronDown } from 'react-icons/io5';
import { AiFillHome } from 'react-icons/ai';
import Conseil from '../Conseil';
import { ListTeams } from '../Teams';
import FormAddTeam from '../FormAddTeam';
import styles from './result-teams.module.scss';

const ResultTeams = ({ active }) => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const { teams } = quizReducer;
  const [sort, setSort] = useState('asc');
  const teamsRef = useRef();
  const containerRef = useRef();

  function handleClickAnchor() {
    const { current: element } = containerRef;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  return (
    <div ref={containerRef} className={!active ? `${styles['result-teams']} ${styles.disabled}` : styles['result-teams']}>
      <div className={styles.header}>
        <Conseil containerClassName={styles.advices} zoningCode='resultats_equipes' />
      </div>
      <div className={styles['container-filters']}>
        {teams && (
          <button onClick={() => setSort((state) => (state === 'asc' ? 'desc' : 'asc'))} className={`${styles.btnSort} secondary`} >
            <IoChevronUp />
            <IoChevronDown />
          </button>
        )}
        <p onClick={() => handleClickAnchor()} className={styles.anchor}>Ajouter une équipe</p>
      </div>
      <div className={styles.container}>
        <div className={styles['container-teams']}>
          <ListTeams
            ref={teamsRef}
            hideAlphabet
            sort={sort}
            teams={teams}
            hideScore={false}
            dropDownSerie
            noScroll
          />
          <div className={styles.containerForm}>
            <FormAddTeam withScore={true} withBonus={false} />
          </div>
        </div>
        <div className={`only-on-tablet ${styles['link-home']}`}>
          <NavLink to='/app/home'>
            <AiFillHome size={24} />
            <p>Quitter l’animation</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ResultTeams;
