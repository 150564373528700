import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiFillHome } from 'react-icons/ai';
import { orderBy } from 'lodash';
import { sendWsEvent } from '../../actions/quiz';
import Conseil from '../Conseil';
import Ranking from '../Ranking';
import styles from './photo-teams.module.scss';

const PhotosTeams = ({ active }) => {
  const quizReducer = useSelector((state) => state.quizReducer);

  function launchRankingOnRemote() {
    sendWsEvent({
      path: 'results',
    });
  }

  function sortTeam() {
    return orderBy(quizReducer.teams, ['points.equipe_quiz_points', 'nom'], ['desc', 'asc']);
  }

  return (
    <div className={!active ? `${styles['photo-teams']} ${styles.disabled}` : styles['photo-teams']}>
      <div className={styles.header}>
        <Conseil containerClassName={styles.advices} zoningCode='resultats_classement' />
      </div>
      <div className={styles.container}>
        <div className={styles['container-ranking']}>
          <Ranking teams={sortTeam().slice(0, 3)} />
          <div className={styles.link}>
            <NavLink to='/app/feedback' onClick={() => launchRankingOnRemote()}>
              <button className="secondary" >Diffuser le classement</button>
            </NavLink>
          </div>
        </div>
        <div className={`only-on-tablet ${styles['link-home']}`}>
          <NavLink to='/app/home'>
            <AiFillHome size={24} />
            <p>Quitter l’animation</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PhotosTeams;
