import React from 'react';
import StyledButton from '../StyledButton';

// const INLINE_STYLES = [
//   { label: 'Bold', style: 'BOLD' },
//   { label: 'Italic', style: 'ITALIC' },
//   { label: 'Underline', style: 'UNDERLINE' },
//   { label: 'Monospace', style: 'CODE' },
// ];

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
];

const InlineStyleControls = ({ editorState, onToggle, onChangeDone }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => <StyledButton
        key={type.label}
        active={currentStyle.has(type.style)}
        label={type.label}
        onToggle={onToggle}
        onChangeDone={onChangeDone}
        style={type.style}
      />)}
    </div>
  );
};

export default InlineStyleControls;
