import axios from 'axios';
import { API_URL } from '../constant';
import {
  ERROR_QUIZ,
} from './types';

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  if (type === ERROR_QUIZ && error.response !== undefined && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }

    if (localStorage.getItem('id')) {
      localStorage.removeItem('id');
    }
    window.location.href = '/';
  }

  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export function getData(errorType, url, dispatch, isAuthReq) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.get(requestUrl, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function postData(errorType, url, dispatch, data, isAuthReq) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.post(requestUrl, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function postDataFormData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {
      timeout: 1000 * 60,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (isAuthReq) {
      config.headers.Authorization = localStorage.getItem('token');
    }

    axios.post(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        if (dispatch && errorType) errorHandler(dispatch, error, errorType);
      });
  });
}

export function putDataFormData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {
      timeout: 1000 * 60,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (isAuthReq) {
      config.headers.Authorization = localStorage.getItem('token');
    }

    axios.put(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        if (dispatch && errorType) errorHandler(dispatch, error, errorType);
      });
  });
}

export function putData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {
      timeout: 1000 * 60,
    };

    if (isAuthReq) {
      config.headers = { Authorization: localStorage.getItem('token') };
    }

    axios.put(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        if (dispatch && errorType) errorHandler(dispatch, error, errorType);
      });
  });
}
