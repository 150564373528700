import React, { useEffect, useState, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BiArrowBack } from 'react-icons/bi';
import { getFeedbackOptions } from '../../actions/quiz';
import { getMediaType, getTypeSupport, isTooLate } from '../../helpers';
import CardPreview from '../../components/CardPreview';
import Conseil from '../../components/Conseil';
import styles from './review.module.scss';

const Review = () => {
  const containerRef = useRef();
  const quizReducer = useSelector((state) => state.quizReducer);
  const history = useHistory();
  const [questions, setQuestions] = useState(null);
  const [showBonus, setShowBonus] = useState(null);
  const [indexSerie, setIndexSerie] = useState(0);
  const [optsFeeback, setOptsFeeback] = useState(null);

  useEffect(() => {
    async function getOptions() {
      const opts = await getFeedbackOptions();
      setOptsFeeback(opts);
    }
    getOptions();
  }, []);

  // SET QUESTIONS
  useEffect(() => {
    if (quizReducer.series && quizReducer.series?.[indexSerie]?.questions.length > 0) {
      // let answerWithMedia = quizReducer.series?.[indexSerie]?.questions.filter(
      //   (d) => d.lien_support_reponse,
      // );
      // if (answerWithMedia) {
      //   answerWithMedia = answerWithMedia.map((d) => ({
      //     ...d,
      //     isAnswer: true,
      //   }));
      // } else { answerWithMedia = []; }
      setQuestions([...quizReducer.series?.[indexSerie].questions]);
    } else {
      setQuestions(null);
    }
  }, [quizReducer.series, indexSerie]);

  function handleChangeIndex(i) {
    containerRef.current.scrollTo(0, 0);
    setShowBonus(false);
    setIndexSerie(i);
  }

  function getBonus() {
    const { session } = quizReducer;
    const indices = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in session.bonus) {
      if (/indice_/.test(key)) indices.push(session.bonus[key]);
    }

    return (
      <>
        <ul>{indices.map((bonus, i) => (
          <li className={styles.bonus} key={`bonus-${i}`}>
            <p className={styles.tag}>
              {i === 0 ? 'indice web' : `indice ${i}`}
            </p>
            <p>
              {bonus}
            </p>
          </li>))}
        </ul>
        <div className={styles['bonus-answer']}>
          <p>{session.bonus.reponse}</p>
        </div>
      </>
    );
  }

  function getMedia(isAnswer, d) {
    let media;
    if (isAnswer && d.lien_support_reponse) {
      media = {
        type: getMediaType(d.lien_support_reponse),
        source: d.lien_support_reponse,
      };
    } else if (!isAnswer && d.lien_support) {
      media = {
        type: getMediaType(d.lien_support),
        source: d.lien_support,
      };
    }

    return media;
  }

  return (
    <div className={styles.review}>
      {isTooLate(quizReducer?.session) && <Redirect to='/app/result/teams' />}
      <div ref={containerRef} className={styles.container}>
        <div className={styles['header-desktop']}>
          <div className={styles.title}>
            <a onClick={() => history.goBack()}><BiArrowBack size={34} /></a>
            <h1>Relecture quiz</h1>
          </div>
          <div>
            <Conseil containerClassName={styles.advices} zoningCode='relecture_quiz_serie' />
          </div>
        </div>
        <Conseil containerClassName={styles['advices-mobile']} zoningCode='relecture_quiz_serie' />
        {questions && (
          <>
            <div className={styles['nav-series']}>
              <div className={styles.title}>
                <h3>Series</h3>
              </div>
              <ul>
                {[
                  ...quizReducer.series.map((serie, i) => (
                    <li
                      key={`serie-${i}`}
                      className={(showBonus || indexSerie !== i) ? styles.disabled : `${styles.active}`}>
                      <button className={styles[`serie-${i + 1}`]} onClick={() => handleChangeIndex(i)}>{i + 1}</button>
                    </li>
                  )),
                  <React.Fragment key={'bonus'}>
                    {quizReducer.session.bonus && (
                      <li
                        className={!showBonus ? styles.disabled : `${styles.active}`}>
                        <button className={styles['button-bonus']} onClick={() => setShowBonus(true)}>bonus</button>
                      </li>
                    )}
                  </React.Fragment >,
                ]}
              </ul>
            </div>
            <div className={styles.list}>
              {showBonus
                ? <div className={styles['container-bonus']}>
                  <h3>Bonus</h3>
                  {getBonus()}
                </div>
                : <>
                  {questions.length > 0 && (
                    <h3>
                      {quizReducer.series[indexSerie].nom ? (
                        quizReducer.series[indexSerie].nom
                      ) : (
                        quizReducer.series[indexSerie]?.questions[0].univers
                      )}
                    </h3>
                  )}
                  <ul>
                    {questions.map((d) => (
                      <li key={`preview-${d.id}`}>
                        <CardPreview
                          question={d.question}
                          answer={d.reponse}
                          commentaire={d.commentaire}
                          rubrique={d.rubrique}
                          difficulty={d.difficulte}
                          index={d.numero}
                          idSerie={quizReducer.series[indexSerie].id}
                          indexSerie={indexSerie}
                          optsFeeback={optsFeeback}
                          content={d}
                          points={d.points.label}
                          questionType={getTypeSupport(d.id_type_support)}
                          responseType={getTypeSupport(d.id_type_support_reponse)}
                          media={d.id_type_support !== 0 ? getMedia(false, d) : {}}
                          mediaAnswer={(d.lien_support_reponse !== '' && d.id_type_support_reponse !== 0)
                            ? getMedia(true, d)
                            : {}
                          }
                          canReload
                        />
                      </li>
                    ))}
                  </ul>
                </>
              }
            </div>
            <div className={`${styles['nav-series']} ${styles.bottom}`}>
              <div className={styles.title}>
                <h3>Series</h3>
              </div>
              <ul>
                {[
                  ...quizReducer.series.map((serie, i) => (
                    <li
                      key={`serie-${i}`}
                      className={(showBonus || indexSerie !== i) ? styles.disabled : `${styles.active}`}>
                      <button className={styles[`serie-${i + 1}`]} onClick={() => handleChangeIndex(i)}>{i + 1}</button>
                    </li>
                  )),
                  <React.Fragment key={'bonus'}>
                    {quizReducer.session.bonus && (
                      <li
                        className={!showBonus ? styles.disabled : `${styles.active}`}>
                        <button className={styles['button-bonus']} onClick={() => setShowBonus(true)}>bonus</button>
                      </li>
                    )}
                  </React.Fragment >,
                ]}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Review;
