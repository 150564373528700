import React, { useEffect, useRef } from 'react';
import Scores from './Scores';
import styles from './correction-serie.module.scss';

const CorrectionSerie = ({
  indexSerie,
  active,
  lastSerie,
  fnNextQuestion,
}) => {
  const containerRef = useRef();
  const listTeamsRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [containerRef.current, indexSerie]);

  return (
    <div className={active ? styles.correction : `${styles.correction} ${styles.disabled}`} ref={containerRef}>
      <div>
        <Scores
          indexSerie={indexSerie}
          listTeamsRef={listTeamsRef}
          lastSerie={lastSerie}
          fnNextQuestion={fnNextQuestion}
        />
      </div>
    </div>
  );
};

export default CorrectionSerie;
