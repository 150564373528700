import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import draftToHtml from 'draftjs-to-html';
import {
  Editor,
  EditorState,
  ContentState,
  convertFromHTML,
  RichUtils,
  convertToRaw,
} from 'draft-js';
// import createCounterPlugin from '@draft-js-plugins/counter';
import InlineStyleControls from './InlineStyleControls';
import styles from './text-editor.module.scss';
import 'draft-js/dist/Draft.css';

// const counterPlugin = createCounterPlugin();
// const { CharCounter } = counterPlugin;

const TextEditor = ({
  name,
  rules,
  maxLength = null,
  maxLines = null,
  defaultValue = '',
  control,
}) => {
  const defaultEditorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(defaultValue)),
  );
  const [editorState, setEditorState] = useState(defaultEditorState);

  function onChangeEditor(state) {
    setEditorState(state);
  }

  function toggleInlineStyle(inlineStyle) {
    onChangeEditor(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle,
      ),
    );
  }

  function getStateLength(state) {
    return state.getCurrentContent().getPlainText().length;
  }

  // Handle max lines
  // eslint-disable-next-line
  function _handleReturn(e, state) {
    const newLength = getStateLength(state) + 1;
    const contentState = convertToRaw(state.getCurrentContent());
    if (typeof maxLength === 'number' && newLength > maxLength) return 'handled';
    if (typeof maxLines === 'number' && contentState.blocks.length > maxLines - 1) return 'handled';
  }

  // Handle max length
  // eslint-disable-next-line
  function _handleBeforeInput(chars, state) {
    const newLength = getStateLength(state) + chars.length;
    if (typeof maxLength === 'number' && newLength > maxLength) return 'handled';
  }

  // Handle max length
  // Handle max lines
  // eslint-disable-next-line
  function _handlePastedText(text, html, state) {
    const newLength = getStateLength(state) + text.length;
    const contentState = ContentState.createFromText(
      state.getCurrentContent().getPlainText() + text,
    );
    const linesCount = contentState.getBlockMap().size;
    if (typeof maxLines === 'number' && linesCount > maxLines) return 'handled';
    if (typeof maxLength === 'number' && newLength > maxLength) return 'handled';
  }

  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: {
            ref,
            onChange,
          },
        }) => {
          function _onChange(state) {
            const html = draftToHtml(convertToRaw(state.getCurrentContent()));
            onChange(html);
            onChangeEditor(state);
          }

          function toggle(state) {
            toggleInlineStyle(state);
          }

          function changeStyleDone() {
            const raw = convertToRaw(editorState.getCurrentContent());
            const html = draftToHtml(raw);
            onChange(html);
          }

          return (
            <div className={styles.editor}>
              <div className={styles['container-text']}>
                <Editor
                  placeholder="Votre message ici..."
                  ref={ref}
                  autoCapitalize={'off'}
                  autoComplete={'off'}
                  autoCorrect={'off'}
                  spellCheck={false}
                  editorState={editorState}
                  handleBeforeInput={_handleBeforeInput}
                  handlePastedText={_handlePastedText}
                  handleReturn={_handleReturn}
                  onChange={(state) => _onChange(state)}
                  onBlur={(e) => {
                    console.log(e);
                  }}
                />
              </div>
              <div className={typeof maxLength === 'number' ? `${styles.options} ${styles.flex}` : styles.options}>
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={(state) => toggle(state)}
                  onChangeDone={changeStyleDone}
                />
                {typeof maxLength === 'number'
                  && <p className={styles.maxLength}>{getStateLength(editorState)}/{maxLength}</p>
                }
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default TextEditor;
