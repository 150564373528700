import React from 'react';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import ItemRanking from './ItemRanking';
import styles from './ranking.module.scss';

const Ranking = ({ sort = 'asc', teams, withScore = true }) => {
  const quizReducer = useSelector((state) => state.quizReducer);
  const { session } = quizReducer;

  function getTeamImage(team) {
    let source;
    const { media } = team;
    if (media?.length > 0) {
      source = team.mediaBaseUrl + media[media.length - 1].nom;
    }
    return source;
  }

  function getTeams() {
    const arr = orderBy(teams, ['points.equipe_quiz_points', 'nom'], ['desc', 'asc']);
    if (sort === 'desc') arr.reverse();
    return arr;
  }

  return (
    <div className={styles.ranking}>
      {(teams && teams.length > 0) && (
        <ul>
          {getTeams().map((team, i) => (
            <ItemRanking
              key={`team-${team.id}`}
              index={sort === 'desc' ? (teams.length - 1) - i : i}
              sessionId={session.id}
              clientId={session.client.id}
              teamId={team.id}
              name={team.nom}
              picture={sort === 'asc' ? i < 3 : i > teams.length - 4}
              point={team.points.equipe_quiz_points}
              thumbnail={getTeamImage(team)}
              withScore={withScore}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default Ranking;
