import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

import { API_URL } from '../../../constant';
import LoaderSpinner from '../../../components/LoaderSpinner';
import styles from '../help.module.scss';

const Contact = () => {
  const history = useHistory();
  const [content, setContent] = useState();

  useEffect(() => {
    async function getContent() {
      const token = localStorage.getItem('token');
      if (token) {
        let res;
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };
        const url = `${API_URL}/quiz/contact`;
        const promise = await fetch(url, config);
        if (promise.ok) res = await promise.json();
        if (res?.contacts) setContent(res.contacts);
      }
    }
    getContent();
  }, []);

  return (
    <div className={styles.contact}>
      {content ? (
        <>
          <div className={styles['header-desktop']}>
            <div className={styles.title}>
              <a onClick={() => history.goBack()}><BiArrowBack size={34} /></a>
              <h1>Nous contacter</h1>
            </div>
          </div>
          <div className={styles.container}>
            {content?.[0]?.nom && (
              <h4 dangerouslySetInnerHTML={{ __html: content[0].nom }} />
            )}
            {content?.[0]?.descriptif_long && (
              <span dangerouslySetInnerHTML={{ __html: content[0].descriptif_long }} />
            )}
          </div>
        </>
      ) : (
        <div className={styles.loader}><LoaderSpinner blue /></div>
      )}
    </div>
  );
};

export default Contact;
