import React, {
  useContext,
  useState,
  useLayoutEffect,
  useImperativeHandle,
  useCallback,
  useEffect,
  forwardRef,
  useRef,
} from 'react';
import { NavLink } from 'react-router-dom';
import {
  useSpring,
  animated,
  to,
  // config,
} from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { withWindowSize } from 'react-fns';
import { IoRemoveOutline } from 'react-icons/io5';
import { FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';
import usePrevious from '../../hooks/usePrevious';
import AnimatedButton from '../AnimatedButton';
import ellipse from './ellipse.svg';
import { quizContext } from '../../pages/quiz/quizContext';
import QuickView from '../QuickView';
import styles from './slider-quiz-nav.module.scss';
import logout from '../../assets/images/icons/logout.svg';

const round = (value, precision = 1) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const SliderQuizNav = withWindowSize(({
  items,
  stepsPause,
  // countSeries,
  detailSerie = {},
  indexSerie = 0,
  currentIndex,
  handleChangeSlide,
  componentRef,
  isQuickViewOpenDesktop = false,
  width,
  height,
}) => {
  const { isQuickViewOpen, setIsQuickViewOpen } = useContext(quizContext);
  const sliderRef = useRef();
  // const offsetMiddleX = useRef();
  const quickviewRef = useRef();
  const [boundSlide, setBoundSlide] = useState();
  const boundPreviousSlide = usePrevious(boundSlide);
  // const [isToucheDevice, setIsToucheDevice] = useState();
  // const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const springHeight = useSpring({
    to: {
      height: (isQuickViewOpen && window.innerWidth < 1024)
        ? (window.innerHeight - 30)
        : 68,
    },
  });

  const [{ pos }, api] = useSpring(() => ({
    to: { pos: [0, 0], index: currentIndex },
    config: {
      mass: 1,
      tension: 500,
      friction: 100,
    },
  }));

  // useEffect(() => {
  //   if (!sliderRef.current || !boundSlide) return;
  //   setIsToucheDevice(getIfTouchDevice());
  // }, [sliderRef.current, boundSlide]);

  function getIfTouchDevice() {
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    return supportsTouch;
  }

  function findClosest(arr, num) {
    return arr.sort((a, b) => Math.abs(b.middle - num) - Math.abs(a.middle - num)).pop();
  }

  const bind = useDrag(
    (props) => {
      const { movement, last, delta } = props;
      if (!getIfTouchDevice()) return;
      let x = movement[0] < 0 ? movement[0] : 0;
      x += delta[0] * 10;
      const y = movement[1];
      if (last) {
        const middle = Math.abs(x);
        const closest = findClosest([...boundSlide], middle);
        const isLast = stepsPause.includes(closest.index);
        handleChangeSlide(closest.index, isLast);
        api.start({ pos: [-(closest.middle), 0], index: closest.index });
      } else {
        api.start({ pos: [x, y] });
      }
    },
    {
      initial: () => pos.get(),
      useTouch: true,
      swipe: { velocity: [100, 100] },
      axis: 'x',
      bounds: () => {
        const lastChild = sliderRef.current.childNodes?.[sliderRef.current.childNodes.length - 1];
        let bounds = { // this is the default props of react-gesture
          top: -Infinity,
          bottom: Infinity,
          left: -Infinity,
          right: Infinity,
        };
        if (lastChild) {
          const { right } = lastChild.getBoundingClientRect();
          bounds = { left: -1 * (right - pos.get()[0]), right: 0 };
        }
        return bounds;
      },
    },
  );

  useEffect(() => {
    if (quickviewRef.current && !isQuickViewOpen) {
      quickviewRef.current.scrollTop();
    }
  }, [
    quickviewRef.current,
    isQuickViewOpen,
  ]);

  const getMiddle = useCallback((target) => {
    const { x: xContainer } = sliderRef.current.parentNode.getBoundingClientRect();
    const bound = target.getBoundingClientRect();
    const currentX = pos.get()[0];
    let targetPosition = (bound.x + (bound.width / 2)) - currentX;
    targetPosition -= xContainer / 2;
    return Math.round(targetPosition - (width / 2));
  }, [width]);

  useLayoutEffect(() => {
    if (!width) return;
    const arr = [];
    sliderRef.current.childNodes.forEach((child, i) => {
      const target = child.childNodes[0];
      arr.push({
        index: i, middle: getMiddle(target),
      });
    });
    setBoundSlide(arr);
  }, [
    currentIndex,
    width,
    height,
  ]);

  function goToSlide(index, serie) {
    let target;
    if (typeof serie === 'number') {
      target = boundSlide[index];
    } else {
      target = boundSlide[index];
    }
    if (!target) return;
    api.start({ pos: [-(target.middle), 0], index });

    // sliderRef.current.scrollTo({
    //   left: target.middle - (eltWidth / 2),
    //   behavior: 'smooth',
    // });
  }

  // INIT SLIDE POSITION
  useEffect(() => {
    if (!boundPreviousSlide && boundSlide) {
      goToSlide(currentIndex, indexSerie);
    }
  }, [currentIndex, boundSlide]);

  useImperativeHandle(componentRef, () => ({
    goToSlide: (index, serie = null) => {
      goToSlide(index, serie);
    },
  }));

  // function centerSlide() {
  //   const closest = findClosest([...boundSlide], offsetMiddleX.current);
  //   const { width: eltWidth } = sliderRef.current.getBoundingClientRect();
  //   const isLast = stepsPause.includes(closest.index);
  //   handleChangeSlide(closest.index, isLast);
  //   sliderRef.current.scrollTo({
  //     left: closest.middle - (eltWidth / 2),
  //     behavior: 'smooth',
  //   });
  // }

  function getClassName(i) {
    let className = styles[`serie-${i < items.length - 2 ? indexSerie + 1 : 'end'}`];
    if (i === currentIndex) {
      className += ` ${styles.active}`;
    } else if (i > currentIndex) {
      className += ` ${styles.disabled}`;
    }

    return className;
  }

  function handleClick(i) {
    goToSlide(i);
    const isLast = stepsPause.includes(i);
    handleChangeSlide(i, isLast);
  }

  function getButtonStyle(d, i) {
    let style = {};
    if (d.value === 'classement') {
      style = { width: '152px' };
    } else if (
      typeof d.value === 'string'
      || (i > currentIndex && d.serie !== items[i - 1]?.serie && typeof items[i - 1]?.serie === 'number')
    ) {
      style = { width: '104px' };
    }
    // let style = {};
    // if (d.value === 'classement') {
    //   style = { width: '152px' };
    // } else if (
    //   typeof d.value === 'string'
    //   || d.value === 1
    // ) {
    //   style = { width: '104px' };
    // }
    return style;
  }

  return (
    <animated.div
      className={isQuickViewOpen ? `${styles.navigation} ${styles.open}` : styles.navigation}
      style={springHeight}
    >
      <div className={(
        isQuickViewOpenDesktop
        || items[currentIndex].value === 'classement'
        || items[currentIndex].value === 'photos'
      ) ? `${styles['info-serie']} ${styles.hide}` : styles['info-serie']}>
        <p>Série {detailSerie.index}</p>
        <p className={styles.univers}>{detailSerie.univers}</p>
      </div>
      <div className={
        items[currentIndex].value === 'pause'
          || items[currentIndex].value === 'fin'
          ? `${styles.toggle} ${styles.show}`
          : styles.toggle} onClick={() => setIsQuickViewOpen((state) => !state)}>
        {!isQuickViewOpen
          ? <div className={styles.open}><span>Afficher les réponses</span><FiChevronDown /></div>
          : <><IoRemoveOutline /><img src={ellipse} alt="ouvrir la quickview" /></>
        }
      </div>
      <div
        className={isQuickViewOpen
          ? `${styles['container-slider']} ${styles['hide-on-mobile']}`
          : styles['container-slider']
        }>
        <animated.div
          {...bind()}
          style={{
            x: to([pos], ([x]) => round(x)),
            touchAction: 'none',
          }}
          ref={sliderRef}
          className={styles.slider}
        >
          {items.map((d, i) => (
            <div key={`child-${i}`} className={`${styles.slide} ${styles[`serie-${indexSerie + 1}`]}`}>
              <AnimatedButton intensity='gentle'>
                <button
                  style={getButtonStyle(d, i)}
                  className={
                    typeof d.value === 'string'
                      ? `${styles.bonus} ${getClassName(i)}`
                      : getClassName(i)
                  }
                  onClick={() => handleClick(i)}
                >
                  {(i > currentIndex && d.serie !== items[i - 1]?.serie && typeof items[i - 1]?.serie === 'number')
                    && (typeof d.value === 'number')
                    ? `Série ${d.serie + 1}` : d.value
                  }
                </button>
              </AnimatedButton>
            </div>
          ))}
        </animated.div>
      </div>
      <div className={`only-on-desktop ${styles['link-home']}`}>
        <NavLink to='/app/home'>
          <img src={logout} alt="quittez l'animation" />
        </NavLink>
      </div>
      <div className={styles['container-quickview']}>
        <QuickView ref={quickviewRef} />
      </div>
    </animated.div>
  );
});

SliderQuizNav.propTypes = {
  countSeries: PropTypes.number,
  indexSerie: PropTypes.number,
  detailSerie: PropTypes.object,
  currentIndex: PropTypes.number,
  handleChangeSlide: PropTypes.func,
};

export default forwardRef((props, ref) => <SliderQuizNav {...props} componentRef={ref} />);
