import React from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { BiArrowBack } from 'react-icons/bi';
import { AiFillHome } from 'react-icons/ai';
import LoaderSpinner from '../LoaderSpinner';
// import { getDate } from '../../helpers';
import styles from './topbar.module.scss';
import help from '../../assets/images/icons/help.svg';
import logout from '../../assets/images/icons/logout.svg';

const TopBar = () => {
  const domNode = document.getElementById('topbar');
  const location = useLocation();
  const history = useHistory();
  const quizReducer = useSelector((state) => state.quizReducer);

  // function getQuizzInfo(hideArrowBack) {
  //   const { session } = quizReducer;
  //   return (
  //     <div className={styles['header-info-quiz']}>
  //       {!hideArrowBack && location.pathname === '/app/result/ranking' && (
  //         <span className={styles.back} onClick={() => history.goBack()}>
  //           <BiArrowBack size={24} />
  //         </span>
  //       )}
  //       <span className={styles.info}>
  //         <h2>Cotton quiz</h2>
  //         <p >
  //           <span className={styles.date}>{getDate(session.date)}</span>
  //           <span className='bold'> {session.heure_debut}</span> <br />
  //           {session.client.nom}, {session.client.ville}
  //         </p>
  //       </span>
  //       <NavLink to="/app/help"><img src={help} alt="aide" /></NavLink>
  //     </div>
  //   );
  // }

  function getContent() {
    let elts;
    switch (location.pathname) {
      case '/app/home':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <a href="https://pro.cotton-quiz.com/extranet/games"><img src={logout} alt="logout" /></a>
                <h2>Bienvenue{'\u00A0'}!</h2>
              </span>
              <NavLink to="/app/help">
                <img src={help} alt="aide" />
                <span>Aide</span>
              </NavLink>
            </div>
          </div>
        );
        break;
      case '/app/teams':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/home')}><AiFillHome size={24} /></span>
                {quizReducer.isLoading && <span className={styles.loader}><LoaderSpinner white={true} size={'sm'} /></span>}
                <h1>{(quizReducer.teams && quizReducer.teams.length >= 1)
                  ? `${quizReducer.teams.length} équipe${quizReducer.teams.length > 1 ? 's' : ''}`
                  : 'Équipes'
                }</h1>
              </span>
              <NavLink to="/app/help">
                <img src={help} alt="aide" />
                <span>Aide</span>
              </NavLink>
            </div>
          </div>
        );
        break;
      case '/app/configuration':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/home')}><AiFillHome size={24} /></span>
                <h1>Personnalisation</h1>
              </span>
              <NavLink to="/app/help">
                <img src={help} alt="aide" />
                <span>Aide</span>
              </NavLink>
            </div>
          </div>
        );
        break;
      case '/app/review':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/home')}><AiFillHome size={24} /></span>
                <h1>Relecture quiz</h1>
              </span>
              <NavLink to="/app/help">
                <img src={help} alt="aide" />
                <span>Aide</span>
              </NavLink>
            </div>
          </div>
        );
        break;
      case '/app/result/teams':
      case '/app/result/ranking':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <a href="https://pro.cotton-quiz.com/extranet/games"><img src={logout} alt="logout" /></a>
                <h1>Classement</h1>
              </span>
            </div>
          </div>
        );
        break;
      case '/app/feedback':
        elts = (
          <div className={styles.container}>
            <div className={`${styles['container-title']} ${styles.feedback}`}>
              <span>
                <span onClick={() => history.goBack()}><BiArrowBack size={24} /></span>
                <h1>Feedback</h1>
              </span>
              <h2>Cotton quiz</h2>
            </div>
          </div>
        );
        break;
      case '/app/help':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/home')}><AiFillHome size={24} /></span>
                <h1>Aide</h1>
              </span>
            </div>
          </div>
        );
        break;
      case '/app/help/faq':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/help')}><BiArrowBack size={24} /></span>
                <h1>FAQ</h1>
              </span>
            </div>
          </div>
        );
        break;
      case '/app/help/rules':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/help')}><BiArrowBack size={24} /></span>
                <h1>Principe et règles du jeu</h1>
              </span>
            </div>
          </div>
        );
        break;
      case '/app/help/contact':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/help')}><BiArrowBack size={24} /></span>
                <h1>Nous contacter</h1>
              </span>
            </div>
          </div>
        );
        break;
      case '/app/help/installation':
        elts = (
          <div className={styles.container}>
            <div className={styles['container-title']}>
              <span>
                <span onClick={() => history.push('/app/help')}><BiArrowBack size={24} /></span>
                <h1>{"Aide à l'installation"}</h1>
              </span>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    let topbar;
    if (location.pathname.startsWith('/app/quiz')) {
      topbar = null;
    } else {
      topbar = <div className={styles.topbar}>{elts}</div>;
    }
    return topbar;
  }

  return createPortal(
    <>
      {getContent()}
    </>,
    domNode,
  );
};

export default TopBar;
