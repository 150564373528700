import React from 'react';
import PropTypes from 'prop-types';
import styles from './media-audio.module.scss';

const MediaAudio = ({ handleClick = null, autoPlay = false, source }) => {
  function _handleClick(e) {
    if (!handleClick) return;
    e.stopPropagation();
    handleClick();
  }

  return (
    <>
      <div className={styles.audio} onClick={(e) => _handleClick(e)}>
        <span style={handleClick ? { pointerEvents: 'none' } : {}}>
          <audio
            controls
            autoPlay={autoPlay}
            src={source}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </span>
      </div>
    </>
  );
};

MediaAudio.propTypes = {
  handleClick: PropTypes.func,
  source: PropTypes.string.isRequired,
};

export default MediaAudio;
