import React, { useState, useEffect, useRef } from 'react';
import Compressor from 'compressorjs';
import { AiOutlineCamera } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { API_URL } from '../../../constant';
import { UPADTE_TEAM } from '../../../actions/types';
import { postTeamImage } from '../../../actions/quiz';
import 'react-html5-camera-photo/build/css/index.css';
import LoaderSpinner from '../../LoaderSpinner';
import styles from './item-ranking.module.scss';

const ItemRanking = ({
  teamId,
  sessionId,
  clientId,
  name,
  point = 0,
  index = 0,
  picture = false,
  withScore = true,
  thumbnail,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [keyTimeStamp, setKeyTimeStamp] = useState(Date.now());
  const {
    control: controlFile,
    watch: watchFile,
  } = useForm();
  const watchFieldFile = watchFile('image');

  function fnCompressFile(file) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.80,
        maxWidth: 1200,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  useEffect(() => {
    if (!watchFieldFile) return;

    async function handleSubmitImage() {
      const formData = new FormData();
      setIsLoading(true);
      const compressFile = await fnCompressFile(watchFieldFile);
      formData.append('file', compressFile);
      formData.append('id_session', sessionId);
      formData.append('id_client', clientId);
      formData.append('id_team', teamId);
      postTeamImage(dispatch, formData).then(async () => {
        const url = `${API_URL}/quiz/teams`;
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };
        let res = null;
        const promise = await fetch(url, config);
        if (promise.ok) res = await promise.json();
        setIsLoading(false);
        if (res?.teams) {
          const team = res?.teams.find((d) => d.id === teamId);
          setKeyTimeStamp(Date.now());
          dispatch({
            type: UPADTE_TEAM,
            payload: [team],
          });
        }
      });
    }
    handleSubmitImage();
  }, [watchFieldFile]);

  return (
    <li
      className={styles['item-ranking']}
      key={`team-${teamId}-${keyTimeStamp}`}
    >
      <div className={styles.index}>{index + 1}</div>
      <div className={`${styles.container}${picture ? '' : ` ${styles['no-picture']}`}`}>
        <div>
          <p className={styles.name}>{name}</p>
          {withScore && <p className={styles.point}><span>{point < 0 ? 0 : point}</span> pts</p>}
        </div>
        {picture && (
          <div className={styles.picture}>
            {thumbnail && <img src={thumbnail} alt={''} />}
            {isLoading && <span className={styles.loader}><LoaderSpinner white size={'sm'} /></span>}
            <InputFile
              name="image"
              defaultValue={null}
              control={controlFile}
            />
          </div>
        )}
      </div>
    </li>
  );
};

const InputFile = ({
  name,
  control,
  defaultValue,
}) => {
  const containerRef = useRef();

  function browserFile(e) {
    const input = containerRef.current.querySelector('input');
    input.click();
    e.stopPropagation();
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{}}
      render={({
        field: {
          // name,
          ref,
          onChange,
        },
      }) => (
        <div onClick={(e) => browserFile(e)} ref={containerRef}
          className={styles['input-file']}
        >
          <input
            style={{ display: 'none' }}
            ref={ref}
            type='file'
            accept="image/png, image/jpeg"
            onChange={(e) => {
              onChange(e.target.files[0]);
            }}
            className={styles.input}
          />
          <div className={styles.icon}>
            <AiOutlineCamera />
          </div>
        </div>
      )}
    />

  );
};

export default ItemRanking;
