import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getMediaType, getTypeSupport } from '../../helpers';
import { sendWsEvent } from '../../actions/quiz';
import styles from './quickview.module.scss';
import CardPreview from '../CardPreview';

// eslint-disable-next-line no-empty-pattern
const QuickView = ({}, ref) => {
  const listRef = useRef();
  const params = useParams();
  const quizReducer = useSelector((state) => state.quizReducer);
  const { series } = quizReducer;
  const [questions, setQuestions] = useState(null);
  const [indexSerie, setIndexSerie] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const serie = params.serie || 0;
    let index = parseInt(serie, 10);
    if (index > series.length - 1) index = series.length - 1;
    setIndexSerie(index);
  }, [params]);

  useImperativeHandle(ref, () => ({
    scrollTop: () => {
      if (listRef.current) {
        listRef.current.scrollTo(0, 0);
      }
    },
  }));

  useEffect(() => {
    function close() {
      setDropdownOpen(false);
    }

    if (dropdownOpen) window.addEventListener('click', close);
    else window.removeEventListener('click', close);

    return () => {
      window.removeEventListener('click', close);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    let arrQuestions = null;
    if (typeof indexSerie === 'number' && quizReducer.series && quizReducer.series?.[indexSerie].questions.length > 0) {
      arrQuestions = quizReducer.series?.[indexSerie].questions;
    } else if (indexSerie === 'bonus') {
      const indices = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in quizReducer.session.bonus) {
        if (/indice_/.test(key)) indices.push(quizReducer.session.bonus[key]);
      }
      arrQuestions = indices;
    }
    setQuestions(arrQuestions);
  }, [quizReducer.series, indexSerie]);

  // useEffect(() => {
  //   console.log(questions);
  // }, [questions]);

  function launchMediaOnRemote(question, index, answer = false) {
    sendWsEvent({
      action: 'launch_media',
      path: 'serie',
      serie: quizReducer.series?.[indexSerie].id,
      question: question.id,
      answer,
      src: getMediaType(!answer ? question.lien_support : question.lien_support_reponse),
    });
  }

  function stopMediaOnRemote() {
    sendWsEvent({
      action: 'stop_media',
    });
  }

  return (
    <div className={styles.quickview}>
      <div className={styles.header}>
        {typeof indexSerie === 'number' && (
          <p>Réponses Série {indexSerie + 1}</p>
        )}
      </div>
      {(typeof indexSerie === 'number' && questions) && (
        <div className={styles.list}>
          <ul ref={listRef}>
            {questions.filter((d) => typeof d === 'object').map((d, i) => (
              <li key={`preview-${d.id}`}>
                <CardPreview
                  isAnswer
                  question={d.question}
                  answer={d.reponse}
                  commentaire={d.commentaire}
                  rubrique={d.rubrique}
                  points={d?.points?.label}
                  difficulty={d.difficulte}
                  index={d.numero}
                  indexSerie={indexSerie}
                  fnLaunchMediaResponseOnRemote={
                    () => launchMediaOnRemote(d, i, true)}
                  fnStopMediaResponseOnRemote={() => stopMediaOnRemote()}
                  questionType={getTypeSupport(d.id_type_support)}
                  responseType={getTypeSupport(d.id_type_support_reponse)}
                  mediaAnswer={(d.lien_support_reponse && d.id_type_support_reponse !== 0)
                    ? {
                      type: getMediaType(d.lien_support_reponse),
                      source: d.lien_support_reponse,
                    } : {}
                  }
                />
              </li>
            ))}
            {quizReducer.session?.bonus?.reponse && (
              <li>
                <div className={`${styles.bonus} ${styles[`serie-${indexSerie + 1}`]}`}>
                  <div className={styles.header}>
                    <p className={`${styles.tag} ${styles[`serie-${indexSerie + 1}`]}`}>
                      <span>?</span>
                      <span>{`indice ${indexSerie + 1}`}</span>
                    </p>
                  </div>
                  <p className={styles.indice}>{quizReducer.session.bonus[`indice_${indexSerie + 1}`]}</p>
                  {quizReducer.session?.bonus?.commentaire && (
                    <p className={styles.comments}>{quizReducer.session.bonus.commentaire}</p>
                  )}
                  <p className={`${styles['bonus-answer']} ${styles[`serie-${indexSerie + 1}`]}`}>
                    Réponse bonus{'\u00A0'}: {quizReducer.session.bonus.reponse}
                  </p>
                </div>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

// {/<div className={styles.header}>
// <p>Parcourir les séries</p>
// <div className={styles['container-dropdown']}>
//   <p onClick={() => setDropdownOpen((state) => !state)}>
//     {typeof indexSerie === 'number' && (
//       <>Série {indexSerie + 1}</>
//     )}
//     {indexSerie === 'bonus' && 'Bonus'}
//     {dropdownOpen ? <IoChevronUp size={'18px'} /> : <IoChevronDown size={'18px'} />}
//   </p>
//   {dropdownOpen && (
//     <ul className={styles.dropdown}>
//       {quizReducer.series.map((serie, i) =>
//       <li key={`item-serie-${serie.id}`} onClick={() => setIndexSerie(i)}>Série {i + 1}</li>)}
//       <li key='item-bonus' onClick={() => setIndexSerie('bonus')}>Bonus</li>
//     </ul>
//   )}
// </div>
// </div>}

// {<CardPreview
// question={d.question}
// answer={d.reponse}
// rubrique={d.rubrique}
// points={d?.points?.label}
// difficulty={d.difficulte}
// index={d.numero}
// indexSerie={indexSerie}
// fnLaunchMediaOnRemote={() => launchMediaOnRemote(d, i)}
// fnStopMediaOnRemote={() => stopMediaOnRemote()}
// fnLaunchMediaResponseOnRemote={
//   () => launchMediaOnRemote(d, i, true)}
// fnStopMediaResponseOnRemote={() => stopMediaOnRemote()}
// questionType={getTypeSupport(d.id_type_support)}
// responseType={getTypeSupport(d.id_type_support_reponse)}
// media={(d.lien_support && d.id_type_support !== 0)
//   ? {
//     type: getMediaType(d.lien_support),
//     source: d.lien_support,
//   } : {}
// }
// mediaAnswer={(d.lien_support_reponse && d.id_type_support_reponse !== 0)
//   ? {
//     type: getMediaType(d.lien_support_reponse),
//     source: d.lien_support_reponse,
//   } : {}
// }
// />}

// {indexSerie === 'bonus' && (
//   <div className={styles['list-bonus']}>
//     <ul>{questions.map((bonus, i) => (
//       <li className={styles.bonus} key={`preview-${i}`}>
//         {typeof bonus === 'string' && (
//           <>
//             <p className={styles.tag}>
//               {i === 0 ? 'indice web' : `indice ${i}`}
//             </p>
//             <p>
//               {bonus}
//             </p>
//           </>
//         )}
//       </li>))}
//       <li className={styles['bonus-answer']}>
//         <p>{quizReducer.session.bonus.reponse}</p>
//       </li>
//     </ul>

//   </div>
// )
// }

export default forwardRef(QuickView);
