import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getQuizSeries } from '../../actions/quiz';
import LoaderSpinner from '../LoaderSpinner';
import styles from './handle-auth.module.scss';

export default function HandleRole(props) {
  const dispatch = useDispatch();
  const quizReducer = useSelector((state) => state.quizReducer);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/');
    }
  }, [quizReducer.series]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/');
    } else if (!quizReducer.series) {
      getQuizSeries(dispatch);
    }
  }, []);

  function getIsLoading() {
    return quizReducer.isLoading || !quizReducer.series;
  }

  return (
    <>
      { getIsLoading()
        ? (
          <div className={styles.loader}><LoaderSpinner blue/></div>
        ) : (
          <>
           {props.children}
          </>
        )}
    </>
  );
}
